import React from 'react';
import { 
    formatGaugeAmount,
    formatMeasurement,
    hhmmampm
} from "../helpers/gaugeHelpers";

const StationReadings = ({ readings, isMissedData, unitOfMeasure, includeRateOfChange, gaugeType, gaugeId, gaugeSlug, precipTotals }) => { 
    let readingItems = [];

    function getROC(readings) {

        let roc = [];
        roc[0] = readings[0].value - readings[1].value;
        roc[1] = readings[1].value - readings[2].value;
        roc[2] = readings[2].value - readings[3].value;
        roc[3] = readings[3].value - readings[4].value;

        //console.log(roc);
        roc[0] = roc[0].toFixed(3);
        roc[1] = roc[1].toFixed(3);
        roc[2] = roc[2].toFixed(3); 
        roc[3] = roc[3].toFixed(3); 
        return roc;
    }

    if( precipTotals ) {
        readingItems.push(
            {
                value: precipTotals["6_hour_total"],
                time: "6 hour total"
            }, {
                value: precipTotals["3_hour_total"],
                time: "3 hour total"
            }, {
                value: precipTotals["2_hour_total"],
                time: "2 hour total"
            }, {
                value: precipTotals["1_hour_total"],
                time: "1 hour total"
            }
        );
        
        
    } else {
        for (var v = 0; v < readings.length && v < 5 ; v++) {
            readingItems.push({
                value: readings[v].measured_value,
                time: readings[v].datetime_of_measurement,
                skippedClass: (readings[v].measured_value === null ? 'missing-values' : '')  
            });
        }          
    }

    let rateOfChange = null;

    console.log(readingItems);

    if(includeRateOfChange && readingItems.length > 4) {
        rateOfChange = getROC(readingItems);
        for( var j=0; j < readingItems.length; j++ ) {
            readingItems[j].rateOfChange = rateOfChange[j]
        }
    } 

    let gaugeReadings = [];
    for( let i=0; i < readingItems.length; i++ ) {
        if( i < 4 ) {
            let value = formatGaugeAmount( readingItems[i].value, formatMeasurement(unitOfMeasure)); 
            let time = null;
            if( precipTotals ) {
                time = readingItems[i].time;
            } else {
                time = hhmmampm(new Date(readingItems[i].time));
            }

            gaugeReadings.push( 
            <li
                key={ i } 
                className={`guage-reading-item ${readingItems[i].skippedClass}`}>
                <span className="gauge-reading-value" dangerouslySetInnerHTML={{ __html: value }}></span>
                <span className="gauge-reading-rate-of-change">{readingItems[i].rateOfChange}</span>
                <span className="gauge-reading-time">{ time }</span>
            </li> );
        }
    }
    

    return( 
        <ul className="guage-reading">{ gaugeReadings }</ul>
    );
}; 

export default StationReadings; 

