import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import ErrorMsg from "../components/ErrorMsg";
import { connect } from "react-redux"; 
import PageWrapper from "../components/PageWrapper";
import CameraCard from "../components/CameraCard";
import FeedbackWidget from "../components/FeedbackWidget";
import CameraModal from "../components/CameraModal";
import SvgIcon from "../components/SvgIcon";
import CameraMap from "../components/CameraMap";
import { 
    togglePreloader,
    showFetchError,
} from "../actions/index";

import GoogleMapReact from 'google-map-react';

class CameraViewContainer extends Component {
    constructor ( props ) {
        super( props ); 

        this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
        this.closeCameraModal = this.closeCameraModal.bind(this);
        this.openCameraModal = this.openCameraModal.bind(this);
        this.buildStationLocationArray = this.getStationLocationArray.bind(this);
        this.stationMapClick = this.stationMapClick.bind(this);

        this.state = {
            cameraViews: [],
            fetchingCameras: false,
            fetchingCamerasSuccessfull: false,
            cameraModalImages: [],
        }
    }

    UNSAFE_componentWillMount() {
        this.props.togglePreloader(true);
        apiRequest( getRoute('cameraViews')) 
    		.then(result => {
                this.props.togglePreloader(false);
                //console.log(result);
                this.setState({
                    cameraViews: result,
                    fetchingCameras: false,
                    fetchingCamerasSuccessfull: true,
                });
            })
            .catch( (error) => {
                //console.log( 'Error', error );
    			this.props.togglePreloader(false); 

                this.setState({
                    cameraViews: [],
                    fetchingCameras: false,
                    fetchingCamerasSuccessfull: true,
                });
            })
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    dismissErrorMessage(){
        this.props.showFetchError({isVisible: false, message: null});
    }



    openCameraModal(cameraIds){
        //console.log(cameraIds);

        // find the cameras we wasnt to add
        let cameraModalImages = [];
        let cameraModalTitle = '';

        for (let i = 0; i < this.state.cameraViews.length; i++) {
            if( cameraIds.includes( this.state.cameraViews[i].id ) ){
                // found one of the camera ids
                cameraModalTitle = this.state.cameraViews[i].station_name;
                for (let b = 0; b < this.state.cameraViews[i].cameras.length; b++) {
                    
                    cameraModalImages.push({
                        imageName: this.state.cameraViews[i].cameras[b]['title'],
                        imageUrl: this.state.cameraViews[i].cameras[b]['photo'],
                    })
                }
                
            }
        }

        if( !cameraModalImages.length ){
            throw "openCameraModal called and was unable to find the images in the collection!"
        }

        this.setState({
            cameraModalImages: cameraModalImages,
            cameraModalTitle: cameraModalTitle
        });

        // this.setState({
        //     cameraModalImages: []
        // })
    }

    closeCameraModal(){
        this.setState({
            cameraModalImages: [],
            cameraModalTitle: '',
        })
    }

    getStationLocationArray(){

        const stations = [];

        for (let i = 0; i < this.state.cameraViews.length; i++) {
            // create the new station if one doesn't exist
            if( !stations[ this.state.cameraViews[i].station_id ] ){
                stations[ this.state.cameraViews[i].station_id ] = {
                    'latitude': this.state.cameraViews[i].latitude,
                    'longitude': this.state.cameraViews[i].longitude,
                    'cameras': []
                };

            }
            
            // add the camera views to the station
            stations[ this.state.cameraViews[i].station_id ].cameras = stations[ this.state.cameraViews[i].station_id ].cameras.concat(this.state.cameraViews[i].cameras);

        } 

        return stations;

    }

    stationMapClick(station_id){
        //console.log(station_id);

        let stations = this.getStationLocationArray();

        if( ! stations[station_id] ){
            throw "stationMapClick - station_id not found in collection!";
        }

        

        let cameraModalImages = [];
        for (let i = 0; i < stations[station_id].cameras.length; i++) {
            
            cameraModalImages.push({
                imageName: stations[station_id].cameras[i]['title'],
                imageUrl: stations[station_id].cameras[i]['photo'],
            });
        }
        
        this.setState({
            cameraModalImages: cameraModalImages,
            cameraModalTitle: station_id
        });



    }


	render() {
        //console.log(this.props.location.pathname);
        let feedbackWidget = this.props.isLoggedIn ? <FeedbackWidget  location={ this.props.location.pathname } /> : null;

        /*
                <ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }    
                />
        */

        // Pass the stations in as 
        const stations = this.getStationLocationArray();

        //console.log(this.state.cameraViews);
        let damCards = [],
            streamCards = [],
            precipCards = [],
            miscCards = [];


        for (let i = 0; i < this.state.cameraViews.length; i++) {
            const item = this.state.cameraViews[i];
            const card = (
                <CameraCard 
                    data={item} 
                    key={ item.station_id + "-" + item.station_name  + "-" +  item.id }  
                    cameraClick={ this.openCameraModal }
                /> 
            );

            //this.state.cameraViews[i];

            switch(this.state.cameraViews[i].type_of) {
                case 'Stream Gauge':
                    streamCards.push(card);
                    break;
                case 'Rain Gauge':
                    precipCards.push(card);
                    break;
                case 'Dam Gauge':
                    damCards.push(card);
                    break;
                default:
                    miscCards.push(card);
                    break;
            } 
        }

        


        let cameraModal =  null;

        if(this.state.cameraModalImages.length ){
            cameraModal = <CameraModal 
                                isVisible={true} 
                                closeModal={this.closeCameraModal} 
                                cameraImages={this.state.cameraModalImages}
                                modalTitle={this.state.cameraModalTitle}
                            />
        } 


		return (
			<div>
                <Helmet>
                    <title>{ pageTitle('Camera Views') }</title>
                </Helmet>

                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching camera views" />

                <CameraMap 
                    stations={stations}
                    stationClick={this.stationMapClick}
                />

                <div className="map-wrap camera-wrap" role="main">

                    { damCards.length > 0 &&
                        <section className="map-content"> 
                            <h2>Dam Gauges</h2>
                            <div className="card-wrap">
                                { damCards }
                            </div>
                        </section>
                    }

                    { streamCards.length > 0 &&
                        <section className="map-content"> 
                            <h2>Stream Gauges</h2>
                            <div className="card-wrap">
                                { streamCards }
                            </div>
                        </section>
                    }

                    { precipCards.length > 0 &&
                        <section className="map-content"> 
                            <h2>Rain Gauges</h2>
                            <div className="card-wrap">
                                { precipCards }
                            </div>
                        </section>
                    }

                    { miscCards.length > 0 &&
                        <section className="map-content"> 
                            <h2>Misc Gauges</h2>
                            <div className="card-wrap">
                                { miscCards }
                            </div>
                        </section>
                    }
                </div>

                { feedbackWidget }

                {cameraModal}

            </div>
		)
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
	}, dispatch)	
}

//export default RequireCapability( withRouter( connect( mapStateToProps, matchDispatchToProps )( BatteryDataContainer ) ), 'canAccessBatteryData' ); 

export default connect( mapStateToProps, matchDispatchToProps )( CameraViewContainer ); 