import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import ErrorMsg from "../components/ErrorMsg";
import { connect } from "react-redux"; 
import PageWrapper from "../components/PageWrapper";
import FeedbackWidget from "../components/FeedbackWidget";
import { 
    togglePreloader,
    showFetchError,
} from "../actions/index";

class BatteryDataContainer extends Component {
    constructor ( props ) {
        super( props ); 

        this.dismissErrorMessage = this.dismissErrorMessage.bind(this)

        this.state = {
            faq: []
        }
    }

    UNSAFE_componentWillMount() {
        this.props.togglePreloader(true);
        apiRequest( getRoute('faq')) 
		.then(result => {
            this.props.togglePreloader(false);
            //console.log(result);
            this.setState({faq: result.results});
        })
        .catch( (error) => {
            //console.log( 'Error', error );
			this.props.togglePreloader(false); 
            /*this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: <p>Could not connect to server.<br/> Please contact your network administrator.</p>,
            };

            this.props.showFetchError( obj );*/
        })
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    dismissErrorMessage(){
        this.props.showFetchError({isVisible: false, message: null});
    }


	render() {
        //console.log(this.props.location.pathname);
        let feedbackWidget = this.props.isLoggedIn ? <FeedbackWidget  location={ this.props.location.pathname } /> : null;

		return (
			<PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle('Frequently Asked Questions') }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching battery gauge data" />
                <ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }    
                />
                <h1 id="main-heading" className="page-title">frequently asked questions</h1>
                
                <div className="faq-container">
                    { this.state.faq.map((item, index) => {
                            return (
                                <div className="faq-item" key={index}>
                                    <h2 className="faq-question">{item.question}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: item.answer }} ></div>
                                </div>

                            )
                        })
                    }
                    
                </div>
                { feedbackWidget }

            </PageWrapper>
		)
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
        isLoggedIn: state.auth.isLoggedIn,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
	}, dispatch)	
}

//export default RequireCapability( withRouter( connect( mapStateToProps, matchDispatchToProps )( BatteryDataContainer ) ), 'canAccessBatteryData' ); 

export default connect( mapStateToProps, matchDispatchToProps )( BatteryDataContainer ); 