
import React, { Component } from "react"; 
import { bindActionCreators } from "redux";
import { 
	withRouter, 
	//BrowserRouter as Router,
	/*Route*/ } from 'react-router-dom';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
//import { Link, browserHistory } from 'react-router';
import { pageTitle } from "../helpers/pageTitle";

//import ReportsContainer from "./ReportsContainer";
import SlideoutPanel from "../components/SlideoutPanel";
import ReportBuilderMenu from "../components/slideout-menu/ReportBuilderMenu";
import Modal from "../components/Modal";
import Preloader from "../components/Preloader";
import SelectGaugeDropdown from "../components/data-explorer/SelectGaugeDropdown";
import CustomReport from "../components/reports/CustomReport";
import DamsReport from "../components/reports/DamsReport";
import PrecipReport from "../components/reports/PrecipReport";
import StreamReport from "../components/reports/StreamReport";
import LowerDonReport from '../components/reports/LowerDonReport';
import TemporalRainfallReport from "../components/reports/TemporalRainfallReport"
import SvgIcon from "../components/SvgIcon";
import {generateReport, parseReportResultData} from "../helpers/reportBuilderHelpers";
import {getRoute, apiRequest} from "../helpers/Api";
import SaveVisualizationModal from "../components/SaveVisualizationModal";
import SaveChartToDashboardModal from "../components/SaveChartToDashboardModal";
import RadioButtonSeries from "../components/dashboard/RadioButtonSeries";
import FeedbackWidget from "../components/FeedbackWidget";

import { 
	toggleSlideoutPanel,
	updateSlideoutMenu,
	toggleModal,
	handleDateTimeInteraction,
	handleTimeValueChange,
	resetTimeValues,
	searchFilterInputChange,
	doShowSearchList,
	setReportTemplateData,
	togglePreloader,
	stationAddedToReport,
	allStationsAddedToReport,
	updateSelectedStationGaugesForModal,
	addStationGaugesToReport,
	stationRemovedFromReport,
	gaugeAddedToReport,
	gaugeRemovedFromReport,
	handleDateReset,
	throwValidationError,
	formValidated,
	handleSetReportResults,
	handleShowReportBuilderView,
	plotGraphsOnSeperateContainer,
	storeChartDataToProps,
	addAllGaugesForSelectedStation,
	setupStationsAndGauges,
	showAlarmsForStandardDonReport,
	throwDateRangeError,
	throwSearchInputError,
	autoUpdateTime,
	deselectGauges,
	selectGauges,
	resetReportState,
	storeGeneratedReportUrl,
	setDashboardVisualizationRequest,
} from "../actions/index";

import { 
	addSavedVisualizationWidgetToDashboard,
	updateSpecificDashboard,
	addWidget
} from "../actions/Dashboard";

import 'react-day-picker/lib/style.css';

	
class ReportResultsContainer extends Component {
	constructor({props}) {
		super(props);

		this.doShowModal = null;
		this.gaugeType = "";
		this.searchTerm = "";
		this.updateDataInterval = null;

		this.handleSlideoutClick = this.handleSlideoutClick.bind(this);
		this.updateGaugeSelectionMenu = this.updateGaugeSelectionMenu.bind(this);
		this.handleAddGaugeToMenu = this.handleAddGaugeToMenu.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleRemoveStationClick = this.handleRemoveStationClick.bind(this);
		this.removeGaugeSelectionFromMenu = this.removeGaugeSelectionFromMenu.bind(this);
		this.addGaugeToReport = this.addGaugeToReport.bind(this);
		this.removeGaugeFromReport = this.removeGaugeFromReport.bind(this);
		this.handleAddStationToMenu = this.handleAddStationToMenu.bind(this);
		this.stationRemovedFromReport = this.stationRemovedFromReport.bind(this);
		this.getReportTemplate = this.getReportTemplate.bind(this);
		this.addAllGaugesForSelectedStation = this.addAllGaugesForSelectedStation.bind(this);
		this.doShowAlarmsToggle = this.doShowAlarmsToggle.bind(this);
		this.stationAddedToReport = this.stationAddedToReport.bind(this);
		this.handleSearchListToggleClick = this.handleSearchListToggleClick.bind(this);
		this.removeGauges = this.removeGauges.bind(this);
		this.addGauges = this.addGauges.bind( this );
		this.handleSaveVisualizationToDashboard = this.handleSaveVisualizationToDashboard.bind(this);
		this.storedUrl = null;
		this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
		this.saveNewDashboardToDatabase = this.saveNewDashboardToDatabase.bind(this);
		this.saveChartToDashboard = this.saveChartToDashboard.bind(this);
		this.addChartToDashboard = this.addChartToDashboard.bind(this);

		this.state = {
			modalcontentToShow: null,
			chartDataForDashboard: null,
			disableEditMenu:false,
			modalOpen:false,
			stationDataFilter: 'all'
		};
	}

	componentDidMount(){ 
		window.scrollTo(0, 0);
		// the report results were called from a dashboard 'visualization' link

		if( this.props.canAcesssThirdPartyData === true ){
			this.setState({stationDataFilter: 'all'});
		}
		else{
			this.setState({stationDataFilter: 'trca' });
		}


		if( this.props.dateTimeSelections.visualizationUrlRequest.link ) {

			this.setState({disableEditMenu: true});
			//console.log(this.props);
			var from = new Date(this.props.dateTimeSelections.visualizationUrlRequest.fromDate);
			var to = new Date(this.props.dateTimeSelections.visualizationUrlRequest.toDate);
			var url = this.props.dateTimeSelections.visualizationUrlRequest.link;
			this.storedUrl = {
				range: {
					from: from,
					to: to,
				},
				url: url,
			};
			this.props.setupStationsAndGauges( this.props.dateTimeSelections.visualizationUrlRequest.gaugeList, false );
			this.handleGenerateReportClick( this.storedUrl );
		} else {
			//this condition gets report data from local storage in the event a user refreshes their browser while in this view. 
			if( !this.props.dateTimeSelections.reportResults ) {
				this.props.history.replace('/reports');
				/*this.storedUrl = localStorage.getItem("report-results");
				
				if( this.storedUrl ) {
					this.storedUrl = JSON.parse(this.storedUrl);
					//console.log(this.storedUrl);
					var from = this.storedUrl.range.from; 
					from = new Date(this.storedUrl.range.from);
					var to = this.storedUrl.range.to;
					to = new Date(this.storedUrl.range.to);
					this.storedUrl.range.from = from;
					this.storedUrl.range.to = to;	
	
					this.props.setupStationsAndGauges( this.storedUrl.stationsAndGauges );
					this.handleGenerateReportClick( this.storedUrl );
				}*/
			}
		}

		//Defaults to stream template
		this.props.toggleSlideoutPanel(true);
		if( this.props.dateTimeSelections.reportResults )  {
			this.props.storeChartDataToProps(this.props.dateTimeSelections.reportResults);
		}

		var dis = this;
		if( this.props.dateTimeSelections.dateTimeToPresentChecked ) {
			this.updateDataInterval = setInterval( function(){
				var rangeObj = {
					from: dis.props.dateTimeSelections.dateTime.range.from,
					to: new Date(),
				};

				dis.props.autoUpdateTime();
				dis.props.handleDateTimeInteraction( rangeObj );
				dis.handleGenerateReportClick();
			}, 300000 );
		}
	}

	componentWillUnmount() {
		clearInterval(this.updateDataInterval);
	}


	UNSAFE_componentWillReceiveProps(nextProps){
		if( this.props.canAcccesStandardReports === true && nextProps.canAcccesStandardReports === false  ){
			this.props.resetReportState();
			this.props.history.replace('/reports');
		}

		if( this.props.canAcccesStandardReports === false && nextProps.canAcccesStandardReports === true  ){
			this.props.resetReportState();
			this.props.history.replace('/reports');
		}
	}

	shouldComponentUpdate( nextProps, nextState ) {
		if( this.props.handleToggleSlideoutPanel !== nextProps.handleToggleSlideoutPanel  ) {
			if( this.props.dateTimeSelections.selectedStandardReport !== "TEMPORAL" ) {
				this.handleGenerateReportClick();
			}
		}
		return true;
	}

	handleSlideoutClick() {
		this.props.handleToggleSlideoutPanel ? 
		this.props.toggleSlideoutPanel(false) : this.props.toggleSlideoutPanel(true)
	}

	updateGaugeSelectionMenu(type, action) {
		//Gauge selected from search list then added to slideout menu
		var payload = {
			type: type,
			action: action
		};
		
		this.props.updateSlideoutMenu( payload );
	}

	handleAddGaugeToMenu( e ) {
		this.props.toggleModal(true);
		this.setState({ modalcontentToShow: "stationSelector",modalOpen:true });
	}

	handleAddStationToMenu( e ) {
		this.props.toggleModal(true);
		this.setState({ modalcontentToShow: "stationSelector" });
	}

	handleCloseModal(  ) {
		this.props.toggleModal( false );
		this.setState({ modalOpen:false });
	}

	handleRemoveStationClick( e ) {  }

	stationRemovedFromReport(obj) {
		if( this.props.dateTimeSelections.reportResults ) {
			this.handleGenerateReportClick();
		}

		this.props.stationRemovedFromReport(obj);
	}
	
	addGaugeToReport( gauge, stationId ) {
        var obj = {
            gaugeId: gauge,
            stationId: stationId,
		};

		this.props.gaugeAddedToReport( obj );
		this.handleGenerateReportClick();
    }

	removeGaugeSelectionFromMenu( gauge, stationId ) {
		var obj = {
			gauge: gauge,
			station: stationId,
			reportType: this.props.dateTimeSelections.reportType
		};

		var doGenerateReport = false;

		if( this.props.dateTimeSelections.reportResults ) {
			for( var i = 0; i < this.props.dateTimeSelections.selectedStations.length; i++ ) {
				if( this.props.dateTimeSelections.reportResults.type_of === "standard_report" ) {
					doGenerateReport = true;
				} else {
					for( var j = 0; j < this.props.dateTimeSelections.selectedStations[i].selectedGauges.length; j++ ) {
						if( this.props.dateTimeSelections.selectedStations[i].selectedGauges.length > 0) {
							doGenerateReport = true;
						}
					}
				}
			}
		} else {
			this.props.gaugeRemovedFromReport( obj );
		}

		if( doGenerateReport ) {
			this.props.gaugeRemovedFromReport( obj );				
			this.handleGenerateReportClick();
		}
	}
	
	removeGaugeFromReport( gauge, stationId ) {
        var obj = {
            station: stationId,
			gauge: gauge,
		};

		this.props.gaugeRemovedFromReport( obj );
		this.handleGenerateReportClick(this.storedUrl);
	}
    
    handleGenerateReportClick( storedUrl ) {
		var url = null;
		if( storedUrl ) {
			url = storedUrl.url;
		} else {
			url = generateReport(this.props);
		}

		this.props.storeGeneratedReportUrl(url);

		if( url ) {
			this.props.togglePreloader(true);
			apiRequest(url)
			.then(result => {
				//console.log(result);
				this.props.togglePreloader(false);
				var reportDetailDataObj = parseReportResultData(result, this.props.dateTimeSelections.plotGraphOnSeperateContainer );
				this.props.handleSetReportResults(reportDetailDataObj);
				this.props.storeChartDataToProps(this.props.dateTimeSelections.reportResults);
				this.props.setDashboardVisualizationRequest(null);
			}); 
		} 
	}

	getReportTemplate(alarms, dateRange){
		let reportTemplate = null;
		let dateTimes = null;
		if( dateRange ) {
			dateTimes = dateRange;
		} else {
			dateTimes = this.props.dateTimeSelections.dateTime;
		}
		//console.log(this.props.dateTimeSelections);
		const chartType = this.props.dateTimeSelections.chartObjects.report.template;

		var plotGraphs = null;

		if( this.storedUrl ) {
			plotGraphs = this.storedUrl.plotGraphOnSeperateContainer;
		} else {
			plotGraphs = this.props.dateTimeSelections.plotGraphOnSeperateContainer; 
		}

		//console.log(plotGraphs);
		//console.log(chartType);
		let storedChart = this.storedUrl ? true : false;
		//console.log(this.storedUrl);
		//console.log(this.props.dateTimeSelections.chartObjects);
	    switch( chartType ) {
			case "Dams":
	    		reportTemplate = 
				<DamsReport 
					chartData={ this.props.dateTimeSelections.chartObjects } 
					saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }
					canAcccesStandardReports={ this.props.canAcccesStandardReports }
					saveChartToDashboard={ this.saveChartToDashboard }
					stationGaugeList={ this.props.dateTimeSelections.masterStationAndGaugeList }	
					dateTime={ dateTimes }/>
					
	    		break;
			case "Precipitation":
				//console.log(this.props.dateTimeSelections.chartObjects);
	    		reportTemplate = 
				<PrecipReport 
					chartData={ this.props.dateTimeSelections.chartObjects } 
					saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }
					canAcccesStandardReports={ this.props.canAcccesStandardReports }
					dateTime={ dateTimes } 
					saveChartToDashboard={ this.saveChartToDashboard }	
					plotGraphsSeperately={ plotGraphs } />
	    		break;
	    	case "Water Level":
	    		reportTemplate = 
				<StreamReport 
					saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }
					canAcccesStandardReports={ this.props.canAcccesStandardReports }
					chartData={ this.props.dateTimeSelections.chartObjects }
					saveChartToDashboard={ this.saveChartToDashboard }	 
					dateTime={ dateTimes } />
				break;
			case "Temporal Rainfall":
	    		reportTemplate = 
				<TemporalRainfallReport 
					chartData={ this.props.dateTimeSelections.chartObjects } 
					saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }
					canAcccesStandardReports={ this.props.canAcccesStandardReports }
					dateTime={ dateTimes }/>
	    		break;
	    	case "Lower Don":
				reportTemplate = 
				<LowerDonReport 
					chartData={ this.props.dateTimeSelections.chartObjects } 
					dateTime={ dateTimes } 
					saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }
					canAcccesStandardReports={ this.props.canAcccesStandardReports }
					saveChartToDashboard={ this.saveChartToDashboard }	
					showAlarms={ this.props.dateTimeSelections.showDonRiverAlarms }/>
	    		break;	
			default:
				reportTemplate = 
				<CustomReport 
					chartData={ this.props.dateTimeSelections.chartObjects } 
					saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }
					canAcccesStandardReports={ this.props.canAcccesStandardReports }
					dateTime={ dateTimes } 
					plotSeperately={plotGraphs}
					saveChartToDashboard={ this.saveChartToDashboard }	
					storedChart={ storedChart }
				/>
					
                break;
        }
		return reportTemplate;
	}
	addAllGaugesForSelectedStation(e) {
		this.props.addAllGaugesForSelectedStation( e.currentTarget.dataset.station );
		if( this.props.dateTimeSelections.reportResults ) {
			this.handleGenerateReportClick();
		}
	}
	
	doShowAlarmsToggle(e) {
		//console.log(e.target.checked);
		this.props.showAlarmsForStandardDonReport(e.target.checked)
	}

	handleSearchListToggleClick(e) {
		e.preventDefault();
		this.props.doShowSearchList();
	}

	stationAddedToReport(obj) {
		this.props.stationAddedToReport(obj);

		if( this.props.dateTimeSelections.reportResults ) {
			this.handleGenerateReportClick();
		}
	}

	removeGauges(gauges, stationId){
		//console.log("sup????");
		this.props.deselectGauges( gauges );
		this.removeGaugeFromReport( gauges, stationId );
	}

	addGauges(gauges, stationId){
		this.props.selectGauges( gauges ); 
		this.addGaugeToReport( gauges, stationId );
    }
	
	doRenderSearchInput( filteredStations, stationsOnly ) {
		
		let searchInputValidationMsg = this.props.dateTimeSelections.searchInputValidationErrorMsg;
		if( this.props.dateTimeSelections.selectedStandardReport === "DAM" || this.props.dateTimeSelections.selectedStandardReport === "LOWER_DON" || this.props.dateTimeSelections.selectedStandardReport === "TEMPORAL" ) {
			return null;
		} else {
			let stationData = [];
			let gaugeRemoveable = true;
			let stationRemoveable = true;
			let ctr = 0;
			let stationCounter = 0;

			for (let i = 0; i < filteredStations.length; i++) {
				const theStation = filteredStations[i];
				let gauges = [];

				// apply filting
				// when trca filter on only show trca stations
				if( this.state.stationDataFilter === 'trca' && theStation.isThirdParty === true ){
					//console.log('skipping gauge for not trca');
					continue;
				}

				// when third party filter on is on filter out trca stations
				if( this.state.stationDataFilter === 'third-party' && theStation.isThirdParty === false){
					//console.log('skipping gauge for not third party');
					continue;
				}

				for (let b = 0; b < theStation.gauges.length; b++) {
					gauges.push(  {
						id: theStation.gauges[b].id,
						sensorName: theStation.gauges[b].sensorName,
						selected: theStation.gauges[b].selected,
						removeable: gaugeRemoveable,
						searchableString: theStation.searchString,
					}); 

					if( theStation.gauges[b].selected ) {
						ctr++;
					}
				}
	
				let station = {
					id: theStation.id,
					stationName: theStation.stationName,
					removeable: stationRemoveable,
					gauges: gauges,
					showThirdPartyFlag: theStation.isThirdParty
				}
	
				stationData.push(station);
			}

			if( ctr === 1 && !stationsOnly ) {
				for( let a = 0; a < stationData.length; a++ ) {
					for( let b = 0; b < stationData[a].gauges.length; b++ ) {
						if(stationData[a].gauges[b].selected === true) {
							stationData[a].gauges[b].removeable = false;
						}
					}
				}
			} 
			

            if( stationsOnly ) {
				for( let c = 0; c < stationData.length; c++ ) {
					ctr = 0;
					for( let d = 0; d < stationData[c].gauges.length; d++ ) {
						if(stationData[c].gauges[d].selected === true) {
							ctr++;
						}
					}
					if( ctr > 0 ) {

						stationCounter ++;
					}
				}

				if( stationCounter === 1 ) {
					for( let e = 0; e < stationData.length; e++ ) {
						ctr = 0;
						for( let f = 0; f < stationData[e].gauges.length; f++ ) {
							if(stationData[e].gauges[f].selected === true) {
								stationData[e].removeable = false;			
							}
						}
					}
				}
			}

			return(
				<div>
					<SelectGaugeDropdown
						inputId="report-results-station-search"
						stationsOnly={stationsOnly} 
						giveFocus={ this.state.modalOpen }
						stations={stationData}
						addGauges={this.addGauges}
						allowSelectAll={true}
						removeGauges={this.removeGauges}
						stationAddedToReport={ this.props.stationAddedToReport }
					/>
					<span id="search-input-validation" className="error-msg" >{ searchInputValidationMsg }</span>
				</div>
			);
		}
	}

	handleSaveVisualizationToDashboard() {
		this.props.toggleModal(true);
		this.setState({ modalcontentToShow: "saveToDashboard" });
	}

	addWidgetToDashboard(obj) {
		//console.log(obj);
		//this.props.addSavedVisualizationWidgetToDashboard( obj );
		this.props.addWidget( obj );
		let _this = this;
		setTimeout(function(){_this.saveNewDashboardToDatabase(obj)},2000); //find another way to handle this
	}

	saveNewDashboardToDatabase(obj) {

		for( var i=0; i < this.props.availableDashboards.length; i++ ) {
			if( this.props.availableDashboards[i].name === obj.options.dashboardToAdd ) {

				let dashboard = {
					name: obj.options.dashboardToAdd,
					widgets: this.props.availableDashboards[i].widgets,
					layout: this.props.availableDashboards[i].layout,
					is_default: this.props.availableDashboards[i].is_default,
				}

				//console.log(dashboard);

				apiRequest(getRoute('getDashboard', [this.props.availableDashboards[i].id]), {
					method: 'PUT',
					body: JSON.stringify(dashboard)
				}).then(theResult => {
					//console.log('dashboard saved!', theResult);
					this.setState({dashboardSaving: false});
					this.props.updateSpecificDashboard( theResult );
					this.setState({ modalcontentToShow: "dashboardSaveSuccess" });
					var _this = this;
					setTimeout(function(){_this.props.toggleModal(false) },1000);

				}).catch(error => {
					//console.log( 'savingDashboard Error', error );
					this.setState({dashboardSaving: false});
				});
			}
		}
	}

	saveChartToDashboard(obj) {
		//console.log(obj);
		this.props.toggleModal(true);
		this.setState({ modalcontentToShow: "saveChartToDashboard", chartDataForDashboard: obj });
	}

	addChartToDashboard(obj) {
		//console.log(obj);
		var _this = this;
		this.props.addWidget( obj );
		setTimeout(function(){_this.saveNewDashboardToDatabase(obj)},1000); //find another way to handle this
	}

	render() {
		let stationsOnly = (this.props.dateTimeSelections.reportType === "custom") ? false : true;
		
		let searchInput = this.doRenderSearchInput(this.props.dateTimeSelections.stationsAndAssociatedGauges, stationsOnly);

		let reportTemplate = null;
		if( this.storedUrl ) {
			reportTemplate = this.getReportTemplate(this.props.dateTimeSelections.showDonRiverAlarms, this.storedUrl);
		} else {
			reportTemplate = this.getReportTemplate(this.props.dateTimeSelections.showDonRiverAlarms, null);
		}

		//console.log(this.props.location.pathname);
		let feedbackWidget = this.props.isLoggedIn ? <FeedbackWidget location={ this.props.location.pathname }/> : null;

		let modalContent = null;
		switch( this.state.modalcontentToShow ) {
			case "stationSelector":
				// add the station data filter
				let stationDataFilter = null;

				if( this.props.canAcesssThirdPartyData ){

					 let filterOptions = [
			            {label: "All", value: 'all', id: "filter-all", checked: (this.state.stationDataFilter === 'all' ? true : false )},
			            {label: "TRCA Only", value: 'trca', id: "filter-trca", checked: (this.state.stationDataFilter === 'trca' ? true : false )},
			            {label: "Third Party Only", value: 'third-party', id: "third-party", checked: (this.state.stationDataFilter === 'third-party' ? true : false )},

			        ];
											
					stationDataFilter = (
						<RadioButtonSeries 
			                title="Select a data type"
			                groupName="display-type"
			                options={filterOptions}
			                handleRadioInputChange={ ( value, name, event, btnID)=> { this.setState({stationDataFilter: value})}}
			                required={false}
			                isValid={true}
			                requiredValidationMessage="Please make a selection"
			            />
			        );
				}


				modalContent = 
				<div className="station-search-container" role="search">
					<button 
						className="closeModalBtn" 
						onClick={ this.handleCloseModal }
					>
						<SvgIcon icon="menu-close" />
					</button>
					<h1 className="modal-title">Update Report</h1>
					{ stationDataFilter }
					{ searchInput }
				</div> 
			break;

			case "saveToDashboard":
				//console.log(this.props);
				let url = ( this.storedUrl ) ? this.storedUrl.url : this.props.dateTimeSelections.generatedReportUrl;

				//console.log(url);

				modalContent = 
				<div>
					<SaveVisualizationModal 
						reportUrl={ url }
						plotSeperately={ this.props.dateTimeSelections.plotGraphOnSeperateContainer }
						selectedCharts={ this.props.dateTimeSelections.reportResults.charts }
						dateRange={ this.props.dateTimeSelections.dateTime.range }
						dashboards={ this.props.availableDashboards }
						addWidgetToDashboard={ this.addWidgetToDashboard }
						fromDate={ this.props.dateTimeSelections.dateTime.range.from }
						toDate={ this.props.dateTimeSelections.dateTime.range.to }
						gaugeList={ this.props.dateTimeSelections.stationsAndAssociatedGauges }
					/>
				</div>
			break;

			case "dashboardSaveSuccess":
				modalContent = 
				<div><h3>Success!</h3><p>Your dashboard was updated.</p></div>
			break;

			case "saveChartToDashboard":
				//console.log( this.props.dateTimeSelections );
				modalContent = 
				<SaveChartToDashboardModal 
					chartData={ this.state.chartDataForDashboard }
					dateRange={ this.props.dateTimeSelections.dateTime.range }
					addChartToDashboard={ this.addChartToDashboard }
					dashboards={ this.props.availableDashboards }
				/>
			break;

			default:
			break;
		}


		return (
			
            <div className="slideout-panel-wrap report-results">
            	<Helmet>
                    <title>{ pageTitle('Report Results') }</title>
                </Helmet>

                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Updating Report" />
				<SlideoutPanel  
                        onSlideoutClick={ this.handleSlideoutClick }
                        doSlideoutPanel={ this.props.handleToggleSlideoutPanel }
                        menuData={ 
							<ReportBuilderMenu 
								updateMenuContentData={ this.props.slideoutMenuUpdateData }
								optionsType={ "rainGaugeReport" } 
								handleUpdateGaugeSelection={ this.removeGaugeSelectionFromMenu }
								selectGaugeToAdd={ this.handleAddGaugeToMenu }
								getReportTemplateData={ this.getReportTemplateData }
								selectedStations={ this.props.dateTimeSelections.selectedStations } 
								reportType={ this.props.dateTimeSelections.reportType }
								reportSelection={ this.props.dateTimeSelections.selectedStandardReport }
								showAlarmsToggle={ this.doShowAlarmsToggle }
								alarmsStatus={ this.props.dateTimeSelections.showDonRiverAlarms }
								selectedStandardReport={ this.props.dateTimeSelections.selectedStandardReport }
								reportResults={ this.props.dateTimeSelections.reportResults }
								removeGauges={ this.removeGauges }
								allStationsAndGauges={ this.props.dateTimeSelections.stationsAndAssociatedGauges }
								canAcccesStandardReports={this.props.canAcccesStandardReports}
								disableEditMenu={ this.state.disableEditMenu }
								reportResultsView={ true }
								//saveVisualizationToDashboard={ this.handleSaveVisualizationToDashboard }

							/>
                        }
                    />
                <div className="slideout-main-content-wrap reports-content-container site-content-wrap" role="main">
					<h1 id="main-heading" className="screen-reader-text">Report Results</h1>	
					{ reportTemplate }
					
					<div className="container-fluid">
						{ feedbackWidget }
					</div>
                </div>

                <Modal 
                    isVisible={ this.props.doToggleModal.isVisible }
                    doCloseModal={ this.handleCloseModal }
                    modalContent={ 
						<div>
							<button 
								className="closeModalBtn" 
								onClick={ this.handleCloseModal }
							>
								<SvgIcon icon="menu-close" />
							</button>
							{modalContent} 
						</div>
					}
                />
            </div> 
		)
	}
}

function mapStateToProps(state) {
	return { 
		handleToggleSlideoutPanel: state.toggleSlideoutPanel,
		doToggleModal: state.toggleModal,
		slideoutMenuUpdateData: state.updateSlideoutMenu,
		dateTimeSelections: state.handleDateTimeInteraction, 
		handleTogglePreloader: state.togglePreloader,
		canAcccesStandardReports: state.auth.canAcccesStandardReports,
		availableDashboards: state.dashboard.availableDashboards,
		isLoggedIn: state.auth.isLoggedIn,
		canAcesssThirdPartyData: state.auth.canAcesssThirdPartyData
	 };
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
		toggleSlideoutPanel: toggleSlideoutPanel,
		updateSlideoutMenu: updateSlideoutMenu,
		toggleModal: toggleModal,
		handleDateTimeInteraction: handleDateTimeInteraction,
		handleTimeValueChange: handleTimeValueChange,
		resetTimeValues: resetTimeValues,
		searchFilterInputChange: searchFilterInputChange,
		doShowSearchList: doShowSearchList,
		setReportTemplateData: setReportTemplateData,
		togglePreloader: togglePreloader,
		stationAddedToReport: stationAddedToReport,
		allStationsAddedToReport: allStationsAddedToReport,
		updateSelectedStationGaugesForModal: updateSelectedStationGaugesForModal,
		addStationGaugesToReport: addStationGaugesToReport,
		stationRemovedFromReport: stationRemovedFromReport,
		gaugeAddedToReport: gaugeAddedToReport,
		gaugeRemovedFromReport: gaugeRemovedFromReport,
		handleDateReset: handleDateReset,
		throwValidationError: throwValidationError,
		formValidated: formValidated,
		handleSetReportResults: handleSetReportResults,
		handleShowReportBuilderView: handleShowReportBuilderView,
		plotGraphsOnSeperateContainer: plotGraphsOnSeperateContainer,
		storeChartDataToProps: storeChartDataToProps,
		addAllGaugesForSelectedStation: addAllGaugesForSelectedStation,
		setupStationsAndGauges: setupStationsAndGauges,
		showAlarmsForStandardDonReport: showAlarmsForStandardDonReport,
		throwDateRangeError: throwDateRangeError,
		throwSearchInputError: throwSearchInputError,
		autoUpdateTime: autoUpdateTime,
		deselectGauges: deselectGauges,
		selectGauges: selectGauges,
		resetReportState: resetReportState,
		storeGeneratedReportUrl: storeGeneratedReportUrl,
		addSavedVisualizationWidgetToDashboard: addSavedVisualizationWidgetToDashboard,
		updateSpecificDashboard: updateSpecificDashboard,
		setDashboardVisualizationRequest: setDashboardVisualizationRequest,
		addWidget: addWidget,
	 }, dispatch)
}

export default withRouter( connect( mapStateToProps, matchDispatchToProps )( ReportResultsContainer )); 
