import React, { Component } from "react";
import leftPad from "left-pad";
import weatherRadarLegendImage from '../images/weather-legend.jpg';

class RadarMapLegend extends Component {
    constructor(props) {
        super(props);

        this.state = {
			addRadarLegend: false,
            radarLegendData: null,
            lastRadarUpdate: null,
		};

    }

    
    render() {

        return (
            <div className="weather-radar-map-legend">
                <img src={weatherRadarLegendImage} alt="Weather Radar Legend" />
            </div>
        );
        
    }
};

    export default RadarMapLegend;

 