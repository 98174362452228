import React, { Component } from "react";
import loadScript from '../helpers/loadScript';
import $ from 'jquery';
import { 
	formatMeasurement,
	getTrendIcon,
	getRainIcon,
	yyyymmdd,
	hhmmampm
 } from "../helpers/gaugeHelpers";

import { getRoute, apiRequest } from "../helpers/Api";
import mapStyles from "../json/google-map-styles.json";

class GoogleMap extends Component {

    constructor(props) {
        super(props);
        this.map = null;
        this.markers = {};
        this.initialCenter = {
            lat: 43.6532,
            lng: -79.3832
		};

		this.state = {
			precipMarkerSums: null
		};

        this.updateMarkers = this.updateMarkers.bind(this);
        this.setupArcgisLayer = this.setupArcgisLayer.bind(this);

        // gauge helpers
        this.getMarkerAlarmClass = this.getMarkerAlarmClass.bind(this);
        this.getFirstNonNullReadingPos = this.getFirstNonNullReadingPos.bind(this);


		//layers
		this.watershedLayers = [];
		this.floodPlainLayers = [];
		this.floodLineLayers = [];
		this.rainGaugeIds = [];
		this.arcgisLayer = null;
		this.updateRadarInterval = null;

		this.setupStationData = this.setupStationData.bind(this);
		this.setupWatershedBoundies = this.setupWatershedBoundies.bind(this);
		this.setupFloodPlainViews = this.setupFloodPlainViews.bind(this);
		this.setupFloodLineViews = this.setupFloodLineViews.bind(this);

		this.watershedLayerOn = this.watershedLayerOn.bind(this);
		this.watershedLayerOff = this.watershedLayerOff.bind(this);
		this.floodPlainLayerOn = this.floodPlainLayerOn.bind(this);
		this.floodPlainLayerOff = this.floodPlainLayerOff.bind(this);
		this.floodLineLayerOn = this.floodLineLayerOn.bind(this);
		this.floodLineLayerOff = this.floodLineLayerOff.bind(this);

		this.getGaugeIcon = this.getGaugeIcon.bind(this);
		this.getTrendArrowRotation = this.getTrendArrowRotation.bind(this);
		this.getGaugePopupHtml = this.getGaugePopupHtml.bind(this);
		this.getStationHTML = this.getStationHTML.bind(this);
		this.createMarker = this.createMarker.bind(this);
		this.getPrecipitiationBarHTML = this.getPrecipitiationBarHTML.bind(this);
		this.initialBounds = false;
		this.setupArcgisLayer = this.setupArcgisLayer.bind(this);
		this.updateRadarMapImage = this.updateRadarMapImage.bind(this);
		
	}

	 shouldComponentUpdate(nextProps) {

        //if the last update value has not changed there is no need to re render
        if( this.props.apiData.length !== nextProps.apiData.length){
            // length of the cards have changed, we need to update
            //console.log('updating due to diff count');
            return true;
        }

        // checks to see if we need to update due to one of the gauges changing
        for (var i = 0; i < nextProps.apiData.length; i++) {
        	if( this.props.apiData[i].last_updated !== nextProps.apiData[i].last_updated ){
        		//console.log('updating due to diff last updated');
        		return true;
        	}
        }

        //checks to see if we need to update due to watershed checkbox changing
        if( this.props.watershedBoundariesIsOn !== nextProps.watershedBoundariesIsOn ){
        	return true;
        }

        //checks to see if we need to update due to floodplain checkbox changing
        if( this.props.floodPlainIsOn !== nextProps.floodPlainIsOn ){
        	return true;
		}
		
		//checks to see if we need to update due to floodplain checkbox changing
        if( this.props.floodLineIsOn !== nextProps.floodLineIsOn ){
        	return true;
        }

        //checks to see if we need to update due to the weather radar being turned on
        if( this.props.weatherRadarIsOn !== nextProps.weatherRadarIsOn ){
        	return true;
        }

        //checks to see if we need to update due to precip bars
        if( this.props.precipitationBarsIsOn !== nextProps.precipitationBarsIsOn ){
        	return true;
		}
		
		//checks to see if we need to update due to precip bar timeframe change
        if( this.props.precipitationBarsSelectedTimeframe !== nextProps.precipitationBarsSelectedTimeframe ){
        	return true;
		}

        //console.log('not updating', this.props.apiData, nextProps.apiData);

        // no conditions met, we do not need to update
        return false;
        

    }


	componentDidMount(){
		// Google maps 3rd party library for weather will not 
		// load inside webpack due to requiring google maps to be loaded
		// when the component mounts add the script to the the footer

		//if( window.google && window.google.maps){
			// the maps API is already loaded in the app
			this.googleMapsReady();
		// }
		// else{
		// 	//google maps is not loaded
		// 	const options = {
		// 		id:'google-maps-script', 
		// 		callBack: this.googleMapsReady.bind(this)
		// 	};

		// 	loadScript.addScript( 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxel8Hdnu9UVjOQf9RVvUKWskuah13Sj4', options );
		// }

	}

	componentDidUpdate(){
		//console.log('didUpdate');

		if(this.props.weatherRadarIsOn === 'on'){
			this.map.overlayMapTypes.removeAt(0);
			this.setState({ addRadarLegend: false });
		} else {
			clearInterval(this.updateRadarInterval)
			//console.log( this.updateRadarInterval );
		}

		//the user selected a different time frame value from the precip bar legend so get the new values 
		if( this.props.precipitationBarsSelectedTimeframe ){
        	let minutes = this.props.precipitationBarsSelectedTimeframe * 60;
			let routeDetails = ["getPrecipSums" ,[],{gauge_ids:this.rainGaugeIds,minutes_ago:minutes}];
			apiRequest( getRoute.apply(null, routeDetails))
			.then(result => {       
				this.setState({ precipMarkerSums: result });
				this.updateMarkers();
				this.updateLayers();
			})
			.catch( (error) => {
				//console.log( 'Error', error );
				/*let errorMsg = "";
				switch(error) {
					default:
					errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
					break;
				}
				var obj = {
					isVisible: true,
					message: errorMsg,
				};*/
			})
		} else { //use default api data passed from container
			this.updateMarkers();
			this.updateLayers();
		}
	}

	componentWillUnmount(){
		// when the component unmounts remove it from the document
		// will need to further test this once a router is added and this component is destroyed
		//delete window.google;

		if( document.getElementById('google-maps-script') !== null){
			//google maps is loaded in window after being loaded once if the script is stil on the page remove it
			loadScript.removeScript('google-maps-script');
		}
		loadScript.removeScript('google-arcgis-script');

		clearInterval( this.updateRadarInterval );
	}


    setupWatershedBoundies(){

    	const watershedFolder = 'https://trca-cdn.azureedge.net/trca-kml/';

    	const watershedFiles = [
    		watershedFolder + 'waterCourseKML3.kml', 
			watershedFolder + 'watershedKML3.kml',
    	]

    	for (var i = 0; i < watershedFiles.length; i++) {
    		this.watershedLayers.push(
    			new window.google.maps.KmlLayer({
		            url: watershedFiles[i],
		            preserveViewport: true            
		        })
    		);
    	}

    }

    setupFloodPlainViews(){

        const floodPlainFolder = 'https://trca-cdn.azureedge.net/trca-kml/';
		//setup Flood Plain
		const floodPlainFiles = [
			// floodPlainFolder + 'Carruthers.kmz', 
			// floodPlainFolder + 'Don.kmz',
			// floodPlainFolder + 'Duffins.kmz',
			//floodPlainFolder + 'Etobicoke.kmz',
			// floodPlainFolder + 'Frenchmans.kmz',
			// floodPlainFolder + 'Highland.kmz',
			floodPlainFolder + 'humber12.kmz',
			//floodPlainFolder + 'ClusterMgmtZone.kmz',
			// floodPlainFolder + 'Mimico.kmz',
			// floodPlainFolder + 'Petticoat.kmz',
			// floodPlainFolder + 'Rouge.kmz'
		]; 

		for (var i = 0; i < floodPlainFiles.length; i++) {
			this.floodPlainLayers.push(
				new window.google.maps.KmlLayer({
		            url: floodPlainFiles[i],
		            preserveViewport: true            
		        })
			) 
		}

	}
	
	setupFloodLineViews(){

        const floodLineFolder = 'https://trca-cdn.azureedge.net/trca-kml/';
		//setup Flood Plainn
		const floodLineFiles = [
			floodLineFolder + 'ClusterMgmtZone3.kmz',
		]; 

		for (var i = 0; i < floodLineFiles.length; i++) {
			this.floodLineLayers.push(
				new window.google.maps.KmlLayer({
		            url: floodLineFiles[i],
		            preserveViewport: true            
		        })
			) 
		}
    } 

	googleMapsReady(){
		//console.log("ready");
		this.map = new window.google.maps.Map(document.getElementById(this.props.mapId), {
            zoom: 10,
            center: new window.google.maps.LatLng(this.initialCenter.lat, this.initialCenter.lng),
            //styles: mapStyles,
            mapTypeId: 'terrain'
        });

		this.setupWatershedBoundies();

		this.setupFloodPlainViews();
		this.setupFloodLineViews();
		
		const options = {
			id:'google-arcgis-script', 
			callBack: this.setupArcgisLayer
		};

		loadScript.addScript( process.env.PUBLIC_URL + '/libs/arcgislink.js', options );
		
		this.updateMarkers();
		this.updateLayers();
	}

	updateRadarMapImage() {
		this.map.overlayMapTypes.removeAt(0);
		var currentTime  = new Date();
		//console.log("currentTime: "+ currentTime);
		this.props.radarMapUpdateTime(currentTime);
		this.setState({ addRadarLegend: false });

		const options = {
			id:'google-arcgis-script', 
			callBack: this.setupArcgisLayer
		};

		loadScript.addScript( process.env.PUBLIC_URL + '/libs/arcgislink.js', options );
		
		this.updateMarkers();
		this.updateLayers();
	}

	updateLayers(){

		if( this.props.watershedBoundariesIsOn === 'on' ){
			this.watershedLayerOn();
		}else{
			this.watershedLayerOff();
		}

		if( this.props.floodPlainIsOn === 'on'){
			this.floodPlainLayerOn();
		}
		else{
			this.floodPlainLayerOff();
		}

		if( this.props.floodLineIsOn === 'on'){
			this.floodLineLayerOn();
		}
		else{
			this.floodLineLayerOff();
		}


		if(this.props.weatherRadarIsOn === 'on'){
			this.map.overlayMapTypes.insertAt(0, this.arcgisLayer);
			this.setState({ addRadarLegend: true });

			var _this = this;
			if(!this.updateRadarInterval) {
				
			}

			var currentTime  = new Date();
			this.props.radarMapUpdateTime(currentTime);

			//console.log( this.updateRadarInterval );
			if( !this.updateRadarInterval ) {
				this.updateRadarInterval = setInterval( function(){ _this.updateRadarMapImage() }, 120000 );		
			}
		}
		else{
			this.map.overlayMapTypes.removeAt(0);
			this.setState({ addRadarLegend: false });
			clearInterval( this.updateRadarInterval );
		}

		if(this.props.precipitationBarsIsOn === 'on'){
			//console.log('precip bars on');
		}
		else{
			//console.log('precip bars off');
		}

	}

	watershedLayerOn() { 
		if( this.map === null ){
            throw new Error('watershedLayerOn function called before the google map was initialized');
        }

        for (var i = 0; i < this.watershedLayers.length; i++) {
        	this.watershedLayers[i].setMap(this.map);
        }
	}

	watershedLayerOff() {
		if( this.map === null ){
            throw new Error('watershedLayerOff function called before the google map was initialized');
        }

        for (var i = 0; i < this.watershedLayers.length; i++) {
        	this.watershedLayers[i].setMap(null);
        }
	}


	floodPlainLayerOn() {
		if( this.map === null ){
            throw new Error('floodPlainLayerOn function called before the google map was initialized');
        }

        for (var i = 0; i < this.floodPlainLayers.length; i++) {
        	this.floodPlainLayers[i].setMap(this.map);
        }
	}

	floodPlainLayerOff() {
		if( this.map === null  ){
            throw new Error('floodPlainLayerOff function called before the google map was initialized');
        }

        for (var i = 0; i < this.floodPlainLayers.length; i++) {
        	this.floodPlainLayers[i].setMap(null);
        }
	}

	floodLineLayerOn() {
		if( this.map === null ){
            throw new Error('floodLineLayerOn function called before the google map was initialized');
        }

        for (var i = 0; i < this.floodLineLayers.length; i++) {
        	this.floodLineLayers[i].setMap(this.map);
        }
	}

	floodLineLayerOff() {
		if( this.map === null  ){
            throw new Error('floodLineLayerOff function called before the google map was initialized');
        }

        for (var i = 0; i < this.floodLineLayers.length; i++) {
        	this.floodLineLayers[i].setMap(null);
        }
	}


	setupArcgisLayer(){
		console.log('here');
		this.arcgisLayer = new window.gmaps.ags.MapType(
			'https://mapservices.weather.noaa.gov/eventdriven/rest/services/radar/radar_base_reflectivity/MapServer', 
			{
				name: 'ArcGIS',
				opacity: 0.5
			}
		);
	}


	getTrendArrowRotation(trend, xyCenter){
		let trendDirection = '-90';
		if( trend === 'up' || trend === 'increasing'){
	        trendDirection = '-180';
	    }
	    else if( trend === 'down' || trend === 'decreasing' ){
	        trendDirection = '0';
	    }
	    else if( trend === 'flat' || trend === 'stable'  ){
	        trendDirection = '-90';
	    }

		return `rotate(${trendDirection}, ${xyCenter})`;
	}

	getPrecipitiationBarHTML( gaugeApiData ){
		let data = gaugeApiData;
		let rainTotal = 0;
		
		//user selected new timeframe values from precip bar legend so get the new sums from state 
		if( this.props.precipitationBarsSelectedTimeframe && this.state.precipMarkerSums ) {
			for( var i = 0; i < this.state.precipMarkerSums.length; i++  ) {
				if( gaugeApiData.id === this.state.precipMarkerSums[i].gauge_id ) {
					rainTotal = this.state.precipMarkerSums[i].sum;
				}
			}
		} else { //get sums from default api data passed from container
			data = gaugeApiData;

			for (var r = 0; r < data.time_series_data.length; r++) {
				if( data.time_series_data[r].measured_value !== null ){
					rainTotal = rainTotal + data.time_series_data[r].measured_value;
				}
			}
		}

		//round to 1 decimal place
		rainTotal = rainTotal * 10;
		rainTotal = Math.round(rainTotal);
		rainTotal = rainTotal / 10;
		
		const barHeightEm = 8; // ems
		const barHeight = [
			{
				thresholdGT: 100,
				bgColor: '#004492',
				color: 'white',
				heightPercent: 100,

			},
			{
				thresholdGT: 50,
				bgColor: '#0077ff',
				color: 'white',
				heightPercent: 83.33,
			},
			{
				thresholdGT: 25,
				bgColor: '#59a6ff',
				color: 'black',
				heightPercent: 66.66,
			},
			{
				thresholdGT: 10,
				bgColor: '#a1cdff',
				heightPercent: 50,
			},
			{
				thresholdGT: 2,
				bgColor: '#d2e7ff',
				color: 'black',
				heightPercent: 33.33,
			},
			{
				thresholdGT: -1, //catch all for the rest
				bgColor: '#f0f7ff',
				color: 'black',
				heightPercent: 16.66,
			},
		];

		//console.log("rainTotal: "+rainTotal);

		//find where this bar sits on the scale
		let barPos = 0;
		for (let i = 0; i < barHeight.length; i++) {
			if( rainTotal >= barHeight[i].thresholdGT){
				barPos = i;
				break;
			}
		}

		const barHeightStyle =  barHeightEm * (barHeight[barPos].heightPercent / 100);
		
		const html = 
				`<div class="precip-bar" style="height:${barHeightStyle}em;background-color:${barHeight[barPos].bgColor};color:${barHeight[barPos].color}">
					<span class="precip-bar-value">${rainTotal}</span>
				</div>`;


		//console.log( html );

		return html;

	}

	getGaugePopupHtml(gauge){
		//console.log('gaugeHTML', gauge);

		let svgIcon = '';

		//dont display the trend icon for none
		if( gauge.trendIcon !== 'cross'){
			svgIcon = 
				`<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="icon icon-${gauge.trendIcon}">
			    	<use xlink:Href="#icon-${gauge.trendIcon}"/>
				</svg>`
		}

		let popupHtml = 
			`<div data-gauge="${gauge.type}" class="gauge-popup ${gauge.gaugeAlarmClass} ${gauge.markerPopupPos} ${(gauge.gaugeOpened ? 'gauge-popup-open' : '')}" ${(gauge.gaugeOpened ? 'style="display:block;"' : '')}>
				<h2 class="gauge-popup-title"><a href="/gauge/${gauge.slug}">${gauge.name}</a></h2>
				<div class="guage-popup-value">
					${gauge.latestReading}
					${svgIcon}
				</div>
				<div class="gauge-popup-time">${gauge.latestReadingTime}</div>
				<div class="guage-popup-id">${gauge.stationId}</div>
				<span class="gauge-popup-close">
					<button class="btn-link" data-gauge-close>
						<span class="screen-reader-text">Close</span>
						<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="icon icon-cross">
					    	<use xlink:Href="#icon-cross"/>
						</svg>
					</button>
				</span>				
			</div>`;

		return popupHtml;
	}

	getGaugeIcon(station){

		let gaugeIcon = '';

		if( station.markerType === 'stream'){
			gaugeIcon = 
				`<svg data-click-gauge="Stream Gauge" class="gauge-icon" ${(station.gaugeData[0].trend === null ? 'data-unresponsive' : '')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 34.45">
				    <title>${station.markerInfo[0].name}</title>
				    <polyline data-gauge-fill points="0 30 0 0 31 0 31 30 0 30" fill="#515151" />
				    <rect data-gauge-fill x="12.45" y="26" width="7" height="7" transform="translate(-16.19 19.92) rotate(-45)" fill="#515151" />
				    <path data-name="trend-arrow" transform="${this.getTrendArrowRotation(station.gaugeData[0].trend, '15.5, 15.5')}" d="M17.11,16V6.14H14.59V16H10.85l5,8.7,5-8.7H17.11" fill="#fff" />
				    <path data-name="x" d="M23.58,20.13h0l-4.72-4.71,4.72-4.71h0a.87.87,0,0,0,.11-.18.49.49,0,0,0-.11-.51L21.35,7.79a.49.49,0,0,0-.51-.11.42.42,0,0,0-.18.11h0L16,12.51,11.23,7.79h0a.45.45,0,0,0-.17-.11.49.49,0,0,0-.51.11L8.32,10a.47.47,0,0,0-.11.51.58.58,0,0,0,.11.18h0L13,15.42,8.32,20.13h0a.58.58,0,0,0-.11.18.47.47,0,0,0,.11.51l2.23,2.23a.49.49,0,0,0,.51.11.45.45,0,0,0,.17-.11h0L16,18.33l4.71,4.72h0a.42.42,0,0,0,.18.11.49.49,0,0,0,.51-.11l2.23-2.23a.49.49,0,0,0,.11-.51A.87.87,0,0,0,23.58,20.13Z" fill="#fff"/>
				</svg>`;
		}
		else if( station.markerType === 'dam' ){
			gaugeIcon = 
				`<svg data-click-gauge="Dam Gauge" class="gauge-icon" ${(station.gaugeData[0].trend === null ? 'data-unresponsive' : '')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.96 33.45">
				    <title>${station.markerInfo[0].name}</title>
				    <path data-gauge-fill d="M11.51,0H26.42L36,29H0Z" fill="#515151" />
				    <rect data-gauge-fill x="14.93" y="25" width="7" height="7" transform="translate(-14.75 21.38) rotate(-45)" fill="#515151" />
				    <path data-name="trend-arrow" transform="${this.getTrendArrowRotation(station.gaugeData[0].trend, '18.5, 15.5')}" d="M18.5,24.59l5-8.7H19.74V6H17.22v9.89H13.48Z" fill="#fff" />
				    <path data-name="x" d="M26.06,20.49h0l-4.71-4.71,4.71-4.72h0a.45.45,0,0,0,.11-.17.47.47,0,0,0-.11-.51L23.84,8.15A.5.5,0,0,0,23.32,8a.45.45,0,0,0-.17.11h0l-4.72,4.72L13.72,8.15h0A.45.45,0,0,0,13.55,8a.48.48,0,0,0-.52.11l-2.22,2.23a.49.49,0,0,0-.12.51.48.48,0,0,0,.12.17h0l4.71,4.72-4.71,4.71h0a.52.52,0,0,0-.12.18.49.49,0,0,0,.12.51L13,23.41a.5.5,0,0,0,.52.11.45.45,0,0,0,.17-.11h0l4.71-4.72,4.72,4.72h0a.45.45,0,0,0,.17.11.5.5,0,0,0,.52-.11l2.22-2.23a.47.47,0,0,0,.11-.51A.49.49,0,0,0,26.06,20.49Z" fill="#fff"/>
				</svg>`;
		}
		else if( station.markerType === 'rain' ){
			gaugeIcon = 
				`<svg data-click-gauge="Rain Gauge" class="gauge-icon" ${(station.gaugeData[0].trend === null ? 'data-unresponsive' : '')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 35.45">
				    <title>${station.markerInfo[0].name}</title>
				    <g data-name="Group 2">
				        <rect data-gauge-fill data-name="background-triangle" x="12.45" y="27" width="7" height="7" transform="translate(-16.89 20.21) rotate(-45)" fill="#515151" />
				        <circle data-gauge-fill data-name="background-fill" cx="16" cy="16" r="16" fill="#515151" />
				    </g>
				    <g data-name="Rainy" ${( station.gaugeData[0].trend === 'raining' ? '' : 'display="none"' )}>
				        <path data-name="Path 21" d="M10.72,19.9v5.78a.42.42,0,0,0,.44.4.43.43,0,0,0,.39-.4V19.9a.42.42,0,0,0-.43-.4A.41.41,0,0,0,10.72,19.9Z" fill="#fff" />
				        <path data-name="Path 22" d="M13.41,19.9v4.55a.42.42,0,0,0,.83,0V19.9a.42.42,0,0,0-.44-.4A.41.41,0,0,0,13.41,19.9Z" fill="#fff" />
				        <path data-name="Path 23" d="M16.1,19.9v3.31a.42.42,0,0,0,.43.39.41.41,0,0,0,.4-.39V19.9a.42.42,0,0,0-.44-.4A.41.41,0,0,0,16.1,19.9Z" fill="#fff" />
				        <path data-name="Path 24" d="M18.79,19.9V22a.42.42,0,0,0,.39.44.43.43,0,0,0,.44-.4V19.9a.43.43,0,0,0-.44-.4A.41.41,0,0,0,18.79,19.9Z" fill="#fff" />
				        <path data-name="Path 20" d="M7,15.22a3.43,3.43,0,0,0,3.43,3.43H20.94a3.43,3.43,0,0,0,0-6.86H19.57a3.87,3.87,0,0,0-7.74,0h-1.4A3.43,3.43,0,0,0,7,15.22Z" fill="none" stroke="#fff" stroke-width="2.3" />
				    </g>
				    <g data-name="Sunny" ${( station.gaugeData[0].trend === 'not raining' ? '' : 'display="none"' )}>
				        <path data-name="Path 11" d="M16,22.18a6.29,6.29,0,1,0-6.22-6.29A6.25,6.25,0,0,0,16,22.18Zm0-1.26a5,5,0,1,1,5-5h0A5,5,0,0,1,16,20.92Z" fill="#fff" />
				        <path data-name="Path 12" d="M15.78,5.3V8.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 13" d="M23.46,15.54h3.2" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 14" d="M21.54,21.31l1.92,1.92" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 15" d="M10.66,21.31,8.1,23.87" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 16" d="M10.66,10.42,8.1,8.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 17" d="M20.9,10.42,22.82,8.5" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 18" d="M15.78,23.23v3.2" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				        <path data-name="Path 19" d="M5.54,15.54h3.2" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
				    </g>
				    <path data-name="x" d="M23.63,20.71h0L18.91,16l4.72-4.71h0a.58.58,0,0,0,.11-.18.49.49,0,0,0-.11-.51L21.4,8.37a.49.49,0,0,0-.51-.11.58.58,0,0,0-.18.11h0L16,13.09,11.29,8.37h0a.58.58,0,0,0-.18-.11.49.49,0,0,0-.51.11L8.37,10.6a.49.49,0,0,0-.11.51.58.58,0,0,0,.11.18h0L13.09,16,8.37,20.71h0a.58.58,0,0,0-.11.18.49.49,0,0,0,.11.51l2.23,2.23a.49.49,0,0,0,.51.11.58.58,0,0,0,.18-.11h0L16,18.91l4.71,4.72h0a.58.58,0,0,0,.18.11.49.49,0,0,0,.51-.11l2.23-2.23a.49.49,0,0,0,.11-.51A.58.58,0,0,0,23.63,20.71Z" fill="#fff"/>
				</svg>`;	
		}
		else if( station.markerType === 'cluster' ){


			let secondaryIcon = '';
			let secondaryPos = -1;
			let primaryPos = station.gaugeTypes.indexOf('Rain Gauge')
			// the secondary icon can only be a dam or stream gauge at this time
			if(station.gaugeTypes.indexOf('Dam Gauge') !== -1 ){
				secondaryPos = station.gaugeTypes.indexOf('Dam Gauge');
				secondaryIcon = 
					`<g class="cluster-guage-icon ${station.markerInfo[secondaryPos].gaugeAlarmClass}" data-click-gauge="Dam Gauge" data-name="dam">
				        <path  data-gauge-fill d="M11.51,1.92H26.42l9.54,29H0Z" transform="translate(0.04)" fill="#515151"/>
    					<path d="M18.5,26.52l5-8.7H19.7V7.93H17.18v9.89H13.45Z" transform="translate(0.04) ${this.getTrendArrowRotation(station.gaugeData[secondaryPos].trend,'18.5, 17.5')}" fill="#fff"/>
    					<path data-name="x" d="M26.14,21.94h0l-4.71-4.71,4.71-4.72h0a.48.48,0,0,0,.12-.17.5.5,0,0,0-.12-.52L23.92,9.6a.5.5,0,0,0-.52-.12.48.48,0,0,0-.17.12h0l-4.72,4.71L13.8,9.6h0a.48.48,0,0,0-.17-.12.5.5,0,0,0-.52.12l-2.22,2.22a.5.5,0,0,0-.12.52.48.48,0,0,0,.12.17h0l4.71,4.72-4.71,4.71h0a.66.66,0,0,0-.12.17.5.5,0,0,0,.12.52l2.22,2.22a.5.5,0,0,0,.52.12.48.48,0,0,0,.17-.12h0l4.71-4.71,4.72,4.71h0a.48.48,0,0,0,.17.12.5.5,0,0,0,.52-.12l2.22-2.22a.5.5,0,0,0,.12-.52A.48.48,0,0,0,26.14,21.94Z" transform="translate(-0.04)" fill="#fff"/>
				    </g>`;
			}
			else if( station.gaugeTypes.indexOf('Stream Gauge') !== -1 ){
				secondaryPos = station.gaugeTypes.indexOf('Stream Gauge');
				secondaryIcon = 
					`<g class="cluster-guage-icon ${station.markerInfo[secondaryPos].gaugeAlarmClass}" data-click-gauge="Stream Gauge" data-name="stream">
				        <polyline data-gauge-fill points="2.52 32.54 2.52 2.54 33.52 2.54 33.52 32.54 2.52 32.54" fill="#515151"/>
    					<path d="M19.58,18.56V8.67H17.06v9.89H13.33l5,8.7,5-8.7h-3.8" transform="translate(0.04) ${this.getTrendArrowRotation(station.gaugeData[secondaryPos].trend, '18.5, 17.5')}" fill="#fff"/>
    					<path data-name="x" d="M27.25,22.46h0L21.76,17l5.49-5.49h0a.63.63,0,0,0,.13-.2.58.58,0,0,0-.13-.6L24.66,8.1a.59.59,0,0,0-.6-.13.53.53,0,0,0-.2.13h0l-5.49,5.49L12.88,8.1h0a.53.53,0,0,0-.2-.13.59.59,0,0,0-.6.13L9.49,10.69a.58.58,0,0,0-.13.6.63.63,0,0,0,.13.2h0L15,17,9.49,22.46h0a.57.57,0,0,0,0,.8l2.59,2.59a.55.55,0,0,0,.6.13.44.44,0,0,0,.2-.13h0l5.49-5.48,5.49,5.48h0a.44.44,0,0,0,.2.13.55.55,0,0,0,.6-.13l2.59-2.59a.57.57,0,0,0,0-.8Z" transform="translate(-0.04)" fill="#fff"/>
				    </g>`;
			}
			else{
				throw new Error('cluster did not contain a dam or stream gauge')
			}

			gaugeIcon = 
				`<svg class="gauge-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.69 44.87">
				    <title>${station.markerInfo[0].name}</title>
					
					<g data-click-cluster>
					    <path data-gauge-fill d="M50.32,16,64,41.23s2.37-.81-26.63-.81Z" fill="#515151"/>
						<rect data-gauge-fill x="46.28" y="34.54" width="8.56" height="8.56" transform="translate(-12.64 47.12) rotate(-45)" fill="#515151"/>
					</g>
					<g data-name="cluster-expanded" ${(station.clusterOpen ? 'style="display:inline"' : '' )}>
					    <line data-gauge-stroke x1="49.88" y1="30.02" x2="31.88" y2="22.02" fill="none" stroke="#515151" stroke-width="4" />
					    <line data-gauge-stroke x1="67.88" y1="20.02" x2="51.88" y2="30.02" fill="none" stroke="#515151" stroke-width="4" />

					    <g class="cluster-guage-icon ${station.markerInfo[primaryPos].gaugeAlarmClass}" data-click-gauge="Rain Gauge" ${(station.gaugeData[primaryPos].trend === null ? 'data-unresponsive' : '')}>
					        <g data-name="Group 2">
					            <circle data-gauge-fill cx="80.69" cy="16" r="16" fill="#515151" />
					        </g>
					        <g data-name="Rainy" ${( station.gaugeData[primaryPos].trend === 'raining' ? '' : 'display="none"' )}>
					            <path d="M44.35,1.57V7.36a.42.42,0,0,0,.83,0V1.57a.42.42,0,0,0-.83,0Z" transform="translate(31.06 18.32)" fill="#fff" />
					            <path d="M47,1.57V6.12a.42.42,0,0,0,.39.44.43.43,0,0,0,.44-.4V1.57a.42.42,0,0,0-.44-.39A.41.41,0,0,0,47,1.57Z" transform="translate(31.06 18.32)" fill="#fff" />
					            <path d="M49.73,1.57V4.88a.41.41,0,0,0,.43.39.41.41,0,0,0,.39-.39V1.57a.41.41,0,0,0-.43-.39A.41.41,0,0,0,49.73,1.57Z" transform="translate(31.06 18.32)" fill="#fff" />
					            <path d="M52.42,1.57V3.64a.41.41,0,1,0,.82,0V1.57a.41.41,0,0,0-.43-.39A.41.41,0,0,0,52.42,1.57Z" transform="translate(31.06 18.32)" fill="#fff" />
					            <path d="M40.63-3.1A3.44,3.44,0,0,0,44.06.33H54.57A3.44,3.44,0,0,0,58-3.1a3.44,3.44,0,0,0-3.43-3.44H53.2a3.86,3.86,0,0,0-3.87-3.86,3.86,3.86,0,0,0-3.87,3.86h-1.4a3.44,3.44,0,0,0-3.43,3.43Z" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-width="2.3" />
					        </g>
					        <g data-name="Sunny" ${( station.gaugeData[primaryPos].trend === 'not raining' ? '' : 'display="none"' )}>
					            <path d="M49.63,3.86a6.25,6.25,0,0,0,6.22-6.29,6.25,6.25,0,0,0-6.22-6.29,6.25,6.25,0,0,0-6.22,6.29A6.25,6.25,0,0,0,49.63,3.86Zm0-1.26a5,5,0,0,1-5-5,5,5,0,0,1,5-5,5,5,0,0,1,5,5h0A5,5,0,0,1,49.63,2.6Z" transform="translate(31.06 18.32)" fill="#fff" />
					            <path d="M49.41-13v3.2" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M57.09-2.78h3.2" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M55.17,3,57.09,4.9" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M44.29,3,41.73,5.54" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M44.29-7.9,41.73-9.82" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M54.53-7.9l1.92-1.92" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M49.41,4.9V8.1" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					            <path d="M39.17-2.78h3.2" transform="translate(31.06 18.32)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2" />
					        </g>
					        <path data-name="x" d="M89.89,21.14h0l-5.6-5.6,5.6-5.6h0A.6.6,0,0,0,90,9.73a.59.59,0,0,0-.14-.61L87.25,6.48a.59.59,0,0,0-.61-.14.6.6,0,0,0-.21.14h0l-5.6,5.6-5.6-5.6h0A.6.6,0,0,0,75,6.34a.59.59,0,0,0-.61.14L71.77,9.12a.59.59,0,0,0-.14.61.6.6,0,0,0,.14.21h0l5.6,5.6-5.6,5.6h0a.6.6,0,0,0-.14.21.59.59,0,0,0,.14.61l2.64,2.64a.59.59,0,0,0,.61.14.6.6,0,0,0,.21-.14h0l5.6-5.6,5.6,5.6h0a.6.6,0,0,0,.21.14.59.59,0,0,0,.61-.14L89.89,22a.59.59,0,0,0,.14-.61A.6.6,0,0,0,89.89,21.14Z" transform="translate(-0.04)" fill="#fff"/>
					    </g>
					    <g ${(station.gaugeData[secondaryPos].trend === null ? 'data-unresponsive' : '')}>
				    		${secondaryIcon}
				    	</g>
					</g>
				</svg>`;
		}
		else{
			// The type of station is unknown
			
			throw new Error('An unknown gauge type was passed to getGaugeIcon: ' + station.markerType + ' getGaugeIcon only supports cluster, stream, dam, rain');
		}

		return gaugeIcon;

	}


	formatStationData( stationData ){

	}


	setupStationData(apiData){

		let stations = [];

		//group the gauges into stations
		for (var i = 0; i < apiData.length; i++) {

			const stationId = apiData[i].station_id;

			// only showing WL and PRECIP gauges
			if( ['WL','PRECIP'].indexOf( apiData[i].sensor_name ) === -1  ){
				continue;
			}

			// setup the station if the key ID is not set
			if( stations[stationId] === undefined ){
				stations[stationId] = {
					gaugeData: [],
					gaugeTypes: [],
					markerInfo: [],
					markerHtml: '',
					markerAlarm: '',
					markerType: '',
					clusterOpen: false,
					latLng: {
						lat:apiData[i].latitude,
						lng: apiData[i].longitude
					}
				};

			}

			stations[stationId].gaugeData.push( apiData[i] );
			stations[stationId].gaugeTypes.push(  apiData[i].type_of );

		}

		this.rainGaugeIds = [];
		//modify the data once the stations are grouped
		Object.keys(stations).map( (stationId, index ) => {

			//get the type of station this is
			if( stations[stationId].gaugeTypes.indexOf('Dam Gauge') !== -1 && stations[stationId].gaugeTypes.indexOf('Rain Gauge') !== -1 ){
				// only dam and rain gauges can be clustered, currently a stream gauge will never be at the same station as a dam gauge
				stations[stationId].markerType = 'cluster';
			}
			else if( stations[stationId].gaugeTypes.indexOf('Stream Gauge') !== -1 && stations[stationId].gaugeTypes.indexOf('Rain Gauge') !== -1 ){
				// only dam and rain gauges can be clustered, currently a stream gauge will never be at the same station as a dam gauge
				stations[stationId].markerType = 'cluster';
			}
			else if( stations[stationId].gaugeTypes.indexOf('Stream Gauge') !== -1 ){
				stations[stationId].markerType = 'stream';
			}
			else if( stations[stationId].gaugeTypes.indexOf('Dam Gauge') !== -1 ){
				stations[stationId].markerType = 'dam';
			}
			else if( stations[stationId].gaugeTypes.indexOf('Rain Gauge') !== -1 ){
				stations[stationId].markerType = 'rain';
			}
			else{
				// The type of station is unknown
				throw new Error('An unknown cluster group was detected in setupStationData');
			}

			
			for (var i = 0; i < stations[stationId].gaugeData.length; i++) {

				const gaugeData = stations[stationId].gaugeData[i];

				let markerAttr = {
					stationId: stationId,
					type: stations[stationId].gaugeData[i].type_of,
					name: gaugeData.pretty_station_name,
					slug: gaugeData.slug,
					gaugeAlarmClass: this.getMarkerAlarmClass( gaugeData ),
					markerPopupPos: 'gauge-pos-center',
					trendIcon: '',
					latestReading: '',
					latestReadingTime: '',
					precipBar: '',
					gaugeOpened: false,
				};

				// get trend icon
				if( stations[stationId].gaugeData[i].type_of === 'Rain Gauge'){
					markerAttr.trendIcon = getRainIcon(gaugeData);
	            }
	            else{
	                markerAttr.trendIcon = getTrendIcon(gaugeData);
	            }

	            // setup precip bars
				if( stations[stationId].gaugeData[i].type_of === 'Rain Gauge'){

					markerAttr.precipBar = this.getPrecipitiationBarHTML( stations[stationId].gaugeData[i] );
					this.rainGaugeIds.push( stations[stationId].gaugeData[i].id );
				}

	            // get the first reading
	            var firstReadingPos = this.getFirstNonNullReadingPos(gaugeData.time_series_data);
				if(gaugeData.status === 'Non-responsive' || firstReadingPos === null){
	            	//latest value is not avaialble
	                markerAttr.latestReading = "Not Available";
				}
				else{		
					
					let latestDate = null;

					if( stations[stationId].gaugeData[i].type_of === 'Rain Gauge'){
						//if rain gauge, check to see if a precip legend value is set and set values accordingly 
						if( this.props.precipitationBarsSelectedTimeframe && this.state.precipMarkerSums ) {
							for( var j = 0; j < this.state.precipMarkerSums.length; j++  ) {
								if( this.state.precipMarkerSums[j].gauge_id ===  stations[stationId].gaugeData[i].id ) {
									markerAttr.latestReading = this.state.precipMarkerSums[j].sum + formatMeasurement(gaugeData.unit_of_measure);
									markerAttr.latestReadingTime = "Last "+ this.props.precipitationBarsSelectedTimeframe + " hour(s)";
								}
							}
						} else {
							// latest value avaialble
							markerAttr.latestReading = gaugeData.time_series_data[firstReadingPos].measured_value + formatMeasurement(gaugeData.unit_of_measure);
							latestDate = new Date(gaugeData.time_series_data[firstReadingPos].datetime_of_measurement);
							markerAttr.latestReadingTime = yyyymmdd(latestDate) + ' ' + hhmmampm( latestDate );
						}
					} else {
						// latest value avaialble
						markerAttr.latestReading = gaugeData.time_series_data[firstReadingPos].measured_value + formatMeasurement(gaugeData.unit_of_measure);
						latestDate = new Date(gaugeData.time_series_data[firstReadingPos].datetime_of_measurement);
						markerAttr.latestReadingTime = yyyymmdd(latestDate) + ' ' + hhmmampm( latestDate );
					}

	            	
				}

				// check to see if this is a cluster, if it is update where this popup should be located
				if( stations[stationId].markerType === 'cluster' ){
					if( markerAttr.type === 'Rain Gauge'  ){
						markerAttr.markerPopupPos = 'gauge-right-pos';
					}
					else{
						markerAttr.markerPopupPos = 'gauge-left-pos';
					}
				}

				// add it to the collection
				stations[stationId].markerInfo.push( markerAttr );

			}



			// get the overal marker status level
			if(  stations[stationId].markerInfo.length > 1 ){
				//need to find the highest value gauge alarm
				const alarmPriority = [
					'gauge-normal',
					'gauge-missed',
					'gauge-low',
					'gauge-high',
					'gauge-high-high',
				];

				const maxGaugeIndex = Math.max( alarmPriority.indexOf(stations[stationId].markerInfo[0].gaugeAlarmClass), alarmPriority.indexOf(stations[stationId].markerInfo[1].gaugeAlarmClass) );
				stations[stationId].markerAlarm = alarmPriority[maxGaugeIndex];
			}
			else{
				// only a single gauge use the primary class name
				stations[stationId].markerAlarm = stations[stationId].markerInfo[0].gaugeAlarmClass
			}

			// not sure if this is a great practice?
			return null;

		
		});

		


		return stations;

	}

	getStationHTML(theStation){

		//get the ICON for the gauge
		const markerIcon = this.getGaugeIcon(theStation);
		let markerPopups = '';

		for (var i = 0; i < theStation.markerInfo.length; i++) {
			markerPopups += this.getGaugePopupHtml(theStation.markerInfo[i]);

			if( this.props.precipitationBarsIsOn === 'on' ){
				markerPopups += theStation.markerInfo[i].precipBar;
			}

		}
		let gaugeOpened = '';
		if( theStation.clusterOpen ){
			gaugeOpened = 'gauge-clicked';
		}

		if( theStation.markerInfo[0].gaugeOpened ){
			gaugeOpened = 'gauge-clicked';
		}

		if( theStation.markerInfo[1] && theStation.markerInfo[1].gaugeOpened ){
			gaugeOpened = 'gauge-clicked';
		}

		let isThirdPartyClass = '';

		for (var i = 0; i < theStation.gaugeData.length; i++) {
			if(theStation.gaugeData[i].is_third_party){
				isThirdPartyClass = 'third-party-gauge';
				break;
			}
		}

		var css = `marker trca-map-gauge ${theStation.markerAlarm} ${theStation.markerType} ${gaugeOpened} ${isThirdPartyClass}`;
        let markerHtml = 
            `<div class="${css}" style="position:absolute;">
            	${markerPopups}
                ${markerIcon}
			</div>`;

		return markerHtml;

	}

	createMarker(theStation){

		let marker = {};

	 	//set a google latlng for this marker
		var latLng = new window.google.maps.LatLng(theStation.latLng.lat,  theStation.latLng.lng);

		//this.markers.push(new window.google.maps.Marker(markerAttr));
		marker = new window.google.maps.OverlayView();
		marker.station = theStation;
		marker.htmlString = this.getStationHTML( theStation );
		marker.latlng_ = latLng;
		marker.div_ = null;
		marker.setMap(this.map);

		marker.gaugeFocus = function(){

			$('.trca-map-gauge.gauge-focus').removeClass('gauge-focus');
			$(this.div_).addClass('gauge-focus');
			
		}

		marker.clickMarker = function(event){

			event.preventDefault();
			event.stopImmediatePropagation();
			this.gaugeFocus();

			const type = $(event.delegateTarget).attr('data-click-gauge');
			//update the clicked state for this element
			this.station.markerInfo[this.station.gaugeTypes.indexOf(type)].gaugeOpened = !this.station.markerInfo[this.station.gaugeTypes.indexOf(type)].gaugeOpened;

			if( this.station.markerInfo[this.station.gaugeTypes.indexOf(type)].gaugeOpened === true ){
				//show the popup
				$(this.div_).find(`[data-gauge="${type}"]`).show();
			}
			else{
				//hide the popup
				$(this.div_).find(`[data-gauge="${type}"]`).hide();
			}

		}
		marker.clickCluster = function(event){
			event.preventDefault();
			event.stopImmediatePropagation();

			this.gaugeFocus();

			this.station.clusterOpen = !this.station.clusterOpen;
			if( this.station.clusterOpen === true ){
				$(this.div_).addClass('gauge-clicked');
				$(this.div_).find(`[data-name="cluster-expanded"]`).show();
			}else{
				$(this.div_).removeClass('gauge-clicked');
				$(this.div_).find(`[data-name="cluster-expanded"]`).hide();
				$(this.div_).removeClass('gauge-focus');
			}
		}

		marker.closeGauge = function(event){
			event.preventDefault();
			event.stopImmediatePropagation();

			const $gaugePopup = $(event.delegateTarget).parents('[data-gauge]').first();
			this.station.markerInfo[this.station.gaugeTypes.indexOf($gaugePopup.attr('data-gauge'))].gaugeOpened = false;
			$gaugePopup.hide();
			$(this.div_).removeClass('gauge-focus');
		}
		
		marker.onAdd = function(){
		          
            var markerDiv = this.htmlString;

			this.parsedDiv = $.parseHTML( markerDiv );
			this.div_ = this.parsedDiv[0];
			var panes = this.getPanes();
			panes.overlayImage.appendChild( this.parsedDiv[0] );

		}

		marker.draw = function() {
			// We use the point of the latlong
			// coordinates of the overlay to peg it to the correct position and size.
			// To do this, we need to retrieve the projection from the overlay.
			var overlayProjection = this.getProjection();
			
			// Returns the x and y of the lat long from the top of the screen
			var point = overlayProjection.fromLatLngToDivPixel(this.latlng_);

			//will position the div asbolutely so 0,0 so the top left
		
			var div = this.div_;
			var $div = $(div);
		
			var divWidth = $div.width();
			var divHeight = $div.height();
		
			div.style.left = point.x - ( divWidth / 2 ) + 'px';
			div.style.top = point.y - divHeight + 5 + 'px'; //adding 5 for the triangle

			// when a gauge is clicked in general
			$div.on('click.guage', this.gaugeFocus.bind(this));

			//when a gauge icon is clicked
			$div.find('[data-click-gauge]').on('click.marker', this.clickMarker.bind(this));

			//when a cluster icon is clicked
			$div.find('[data-click-cluster]').on('click.marker', this.clickCluster.bind(this));
			$div.find('[data-gauge-close]').on('click.marker', this.closeGauge.bind(this));
		}
		
		marker.onRemove = function() {
            this.div_.parentNode.removeChild(this.div_);
			this.div_ = null;
		};

		return marker;

	}


	updateMarkers() {
		var  googleBounds = new window.google.maps.LatLngBounds();

		let markerKeys = Object.keys(this.markers);
		const stationData = this.setupStationData( this.props.apiData );
		const stationDataKeys = Object.keys(stationData);

		//remove all entries that do not exist in the new data
		// eslint-disable-next-line
		for (var i = 0; i < markerKeys.length; i++) {
			if( !stationData[markerKeys[i]] ){
				//marker is not set in the new data set
				this.markers[markerKeys[i]].setMap(null);
				delete this.markers[markerKeys[i]];
			}
		}

		// Add or update new markers
		// eslint-disable-next-line
		for (var i = 0; i < stationDataKeys.length; i++) {
			if( this.markers[stationDataKeys[i]] ){
				//need to update
				//console.log('need to update!');

				//only do updates if the type isnt changing from cluster to somtehing else
				if( this.markers[stationDataKeys[i]].station.markerType === stationData[stationDataKeys[i]].markerType ){

					// check to see if the cluster was previously open
					if( this.markers[stationDataKeys[i]].station.clusterOpen ){
						//console.log('the cluster was opened!');
						stationData[stationDataKeys[i]].clusterOpen = true;
					}

					//check to see if the station bubbles are open
					for (var b = 0; b < this.markers[stationDataKeys[i]].station.markerInfo.length; b++) {
						if( this.markers[stationDataKeys[i]].station.markerInfo[b].gaugeOpened ){
							//get the name of the current gauge we are looking into
							const currentGauge = this.markers[stationDataKeys[i]].station.gaugeTypes[b];

							//see if the current gauge is in the new collection
							const newGuageIndex = stationData[stationDataKeys[i]].gaugeTypes.indexOf(currentGauge);

							if( newGuageIndex !== -1 ){
								//if it is still in the markerInfo collection
								stationData[stationDataKeys[i]].markerInfo[newGuageIndex].gaugeOpened = true;
							}

						}	
					}

				} // end checking to see the type changed


				//remove this marker and create a new one
				this.markers[stationDataKeys[i]].setMap(null);
				delete this.markers[stationDataKeys[i]];

				this.markers[stationDataKeys[i]] = this.createMarker( stationData[stationDataKeys[i]] );
			}
			else{
				//need to add
				this.markers[stationDataKeys[i]] = this.createMarker( stationData[stationDataKeys[i]] );
			}
			
		}


		//setup the google bounds 
		markerKeys = Object.keys(this.markers);
		// eslint-disable-next-line
		for (var i = 0; i < markerKeys.length; i++) {
			googleBounds.extend(this.markers[markerKeys[i]].latlng_ );
		}

		
		
		if( Object.keys(this.markers).length && this.initialBounds === false){

			var boundsPadding = null;
			if( this.props.panelOut && document.documentElement.clientWidth >= 500  ){
				boundsPadding = {right:140};
			}
			this.map.fitBounds(googleBounds, boundsPadding);

			this.initialBounds = true;

		}
    }


    getMarkerAlarmClass(stationData){
		//console.log(stationData);

		var firstReadingPos = this.getFirstNonNullReadingPos(stationData.time_series_data);

        if(stationData.status === 'Non-responsive' || firstReadingPos === null /*|| firstReadingPos !== 0*/ ){
			return 'gauge-missed';
		}

		var currentValue = stationData.time_series_data[firstReadingPos].measured_value

        if(stationData.high_high_alarm === true &&  currentValue >= stationData.high_high_limit  ){
            return 'gauge-high-high';
        }

        if( stationData.high_alarm === true && currentValue >= stationData.high_limit ){
            return 'gauge-high';
        }

        if( stationData.low_alarm === true && currentValue >= stationData.low_limit ){
            return 'gauge-low';
        }

        return 'gauge-normal';

    }



	getFirstNonNullReadingPos( stationReadings ){

		var theReading = null;

		for (var i = 0; i < stationReadings.length; i++) {
			
			if( stationReadings[i].measured_value !== null ){
				theReading = i;
				break;
			}
		}

		return theReading;

	}
    
	render() {

		return (
			<div id={this.props.mapId}></div>
		)
	}
}

export default GoogleMap;
