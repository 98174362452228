import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import { yyyymmdd, hhmmampm } from "../helpers/gaugeHelpers";
import ErrorMsg from "../components/ErrorMsg";
import { connect } from "react-redux"; 
import { doAuthCheck } from "../actions/Auth";
import Modal from "../components/Modal";
import SvgIcon from "../components/SvgIcon";
import SaveVisualizationModal from "../components/SaveVisualizationModal";
import PageWrapper from "../components/PageWrapper";
import { 
    togglePreloader,
    showFetchError,
    toggleModal,
} from "../actions/index";

import { 
	updateSpecificDashboard,
	addWidget
} from "../actions/Dashboard";

class BatteryDataContainer extends Component {
    constructor ( props ) {
        super( props ); 

        this.state = {
            batteryData: null,
            modalcontentToShow: null,
			chartDataForDashboard: null,
        };

        this.buildBatterDataTable = this.buildBatterDataTable.bind(this);
        this.getBatteryGaugeMarker = this.getBatteryGaugeMarker.bind(this);
        this.saveVisualizationToDashboard = this.saveVisualizationToDashboard.bind(this);
        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.saveNewDashboardToDatabase = this.saveNewDashboardToDatabase.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.togglePreloader(true);
        apiRequest( getRoute('batteryGauges')) 
		.then(result => {
            //console.log(result);
            this.props.togglePreloader(false);
            this.setState({ batteryData: result });
        })
        .catch( (error) => {
            //console.log(error.status);
            if( error.status === 403 ){
                this.props.doAuthCheck();
                return error;
            }

            //console.log( 'Error', error );
			this.props.togglePreloader(false); 
        })
    }

    buildBatterDataTable(data) {

        let measurements = [];
        for( let i=0; i < data.results.length; i++ ) {
            if(  data.results[i].battery_level_measured_in_volts ) {
                let date = new Date(data.results[i].last_updated);
                let formattedDate = yyyymmdd( date );
                let formattedTime = hhmmampm( date );
                let markerClass = this.getBatteryGaugeMarker( data.results[i].battery_level_measured_in_volts );
                let historicalMarkerClass = this.getBatteryGaugeMarker( data.results[i].lowest_24h_battery_measured_value );

                measurements.push(
                    <tr key={i}>
                        <td>{ data.results[i].station_name }</td>
                        <td>{ formattedDate + " " + formattedTime }</td>
                        <td className="">
                            <div className={ markerClass  }></div>
                            { data.results[i].battery_level_measured_in_volts }
                        </td>
                        <td className="">
                            <div className={ historicalMarkerClass }></div>
                            { data.results[i].lowest_24h_battery_measured_value }
                        </td>
                    </tr>
                );
            }
        }

        return(
            <div className="battery-data-table tabular-stream-gauges">
                <h2>Battery level indication</h2>
                <div className="responsive-table-wrap">
                    <table className="table-lined">
                        <thead>
                            <tr>
                                <th>Station Name</th>
                                <th>Current Time</th>
                                <th className="">Current Battery Level</th>
                                <th className="">Overnight (12-hour) Low</th>
                            </tr>
                        </thead>
                        <tbody>
                            { measurements }
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }

    getBatteryGaugeMarker( volts ) {
        if( volts < 12.1 ) {
            return "battery-marker low"
        } else if( volts >= 12.1 && volts < 12.5 ) {
            return "battery-marker critical"
        } else if( volts >= 12.5 ) {
            return "battery-marker good"
        }
    }

    dismissErrorMessage() {
		/*var obj = {
			isVisible: false,
			message: "",
		};*/

		//this.props.showFetchError( obj );
    }
    
    saveVisualizationToDashboard() {
        this.props.toggleModal(true);
		this.setState({ modalcontentToShow: "saveToDashboard" });
    }

    addWidgetToDashboard(obj) {
		//console.log(obj);
		//this.props.addSavedVisualizationWidgetToDashboard( obj );
		this.props.addWidget( obj );
		let _this = this;
		setTimeout(function(){_this.saveNewDashboardToDatabase(obj)},2000); //find another way to handle this
    }
    
    saveNewDashboardToDatabase(obj) {

		for( var i=0; i < this.props.availableDashboards.length; i++ ) {
			if( this.props.availableDashboards[i].name === obj.options.dashboardToAdd ) {

				let dashboard = {
					name: obj.options.dashboardToAdd,
					widgets: this.props.availableDashboards[i].widgets,
					layout: this.props.availableDashboards[i].layout,
					is_default: this.props.availableDashboards[i].is_default,
				}

				//console.log(dashboard);

				apiRequest(getRoute('getDashboard', [this.props.availableDashboards[i].id]), {
					method: 'PUT',
					body: JSON.stringify(dashboard)
				}).then(theResult => {
					//console.log('dashboard saved!', theResult);
					this.setState({dashboardSaving: false});
					this.props.updateSpecificDashboard( theResult );
					this.setState({ modalcontentToShow: "dashboardSaveSuccess" });
					var _this = this;
					setTimeout(function(){_this.props.toggleModal(false) },1000);

				}).catch(error => {
					//console.log( 'savingDashboard Error', error );
					this.setState({dashboardSaving: false});
				});
			}
		}
    }
    
    handleCloseModal(  ) {
		this.props.toggleModal( false );
	}

	render() {

        let batteryTable = null;
        let batteryLegend = null;
        let modalContent = null;

        let dashboardLink = (this.props.auth.canAccessCustomDashboards) ?
        <button className=" btn-link" onClick={ this.saveVisualizationToDashboard } tabIndex="0" ><SvgIcon icon="floppy-disk"/> Create a link to this report on your dashboard </button> : null;

        if( this.state.batteryData ) {
            batteryTable = this.buildBatterDataTable( this.state.batteryData );
            batteryLegend = 
            <div className="battery-legend-container">
                <div className="legend-item">
                    <div className="marker low"></div>
                    <div className="battery-marker low"></div>{ " Battery Level Low (<12.1 Volt)" }
                </div>
                <div className="legend-item">
                    <div className="marker critical"></div>
                    <div className="battery-marker critical"></div>{ " Battery in critical condition (>= 12.1 Volt and < 12.5 Volt)" }
                </div>
                <div className="legend-item">
                    <div className="marker good"></div>
                    <div className="battery-marker good"></div>{ " Battery in good condition (>= 12.5 Volt)" }
                </div>
            </div>
        }

        switch( this.state.modalcontentToShow ) {

			case "saveToDashboard":
				let url = "/battery-data";
				modalContent = 
				<div>
					<SaveVisualizationModal 
						reportUrl={ url }
						//plotSeperately={ this.props.dateTimeSelections.plotGraphOnSeperateContainer }
						//selectedCharts={ this.props.dateTimeSelections.reportResults.charts }
						//dateRange={ this.props.dateTimeSelections.dateTime.range }
						dashboards={ this.props.availableDashboards }
						addWidgetToDashboard={ this.addWidgetToDashboard }
						//fromDate={ this.props.dateTimeSelections.dateTime.range.from }
						//toDate={ this.props.dateTimeSelections.dateTime.range.to }
						//gaugeList={ this.props.dateTimeSelections.stationsAndAssociatedGauges }
					/>
				</div>
			break;

			case "dashboardSaveSuccess":
				modalContent = 
				<div><h3>Success!</h3><p>Your dashboard was updated.</p></div>
            break;
            
            default: 
            break;
		}

		return (
			<PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle('Station Battery Status') }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching battery gauge data" />
                <ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }	
                />

                <h1 id="main-heading" className="page-title">Station Battery Status</h1>
                { batteryTable }
                { batteryLegend }
                <div className="report-result-buttons">
                    { dashboardLink }
                </div>

                <Modal 
                    isVisible={ this.props.doToggleModal.isVisible }
                    doCloseModal={ this.handleCloseModal }
                    modalContent={ 
						<div>
							<button 
								className="closeModalBtn" 
								onClick={ this.handleCloseModal }
							>
								<SvgIcon icon="menu-close" />
							</button>
							{modalContent} 
						</div>
					}
                />
            </PageWrapper>
		)
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
        doToggleModal: state.toggleModal,
        auth: state.auth,
        availableDashboards: state.dashboard.availableDashboards,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
        doAuthCheck: doAuthCheck,
        toggleModal: toggleModal,
        addWidget: addWidget,
        updateSpecificDashboard: updateSpecificDashboard,
	}, dispatch)	
}

//export default RequireCapability( withRouter( connect( mapStateToProps, matchDispatchToProps )( BatteryDataContainer ) ), 'canAccessBatteryData' ); 

export default connect( mapStateToProps, matchDispatchToProps )( BatteryDataContainer ); 