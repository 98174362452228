import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";
import { Link } from 'react-router-dom';

import SlideoutPanel from "../components/SlideoutPanel";
import MapMenu from "../components/slideout-menu/MapMenu";
import GoogleMap from "../components/GoogleMap";
import ErrorMsg from "../components/ErrorMsg";
import Card from "../components/Card";
import Preloader from "../components/Preloader";
import SvgIcon from '../components/SvgIcon';
import FeedbackWidget from "../components/FeedbackWidget";
import RadarMapLegend from "../components/RadarMapLegend";
import PrecipBarLegend from "../components/PrecipBarLegend"; 
import RainAccumulatedTotals from '../components/station-detailed/RainAccumulatedTotals';
import { 
	togglePreloader, 
	toggleSlideoutPanel,
	updateGaugeMapApiData, 
	showFetchError,
	handleCheckboxUpdate } from "../actions/index";
import slugify from "../helpers/slugify";
import { getRoute, apiRequest } from "../helpers/Api";
import {
	getTrendIcon,
	formatMeasurement,
	formatGaugeAmount,
	yyyymmdd,
	hhmmampm
} from "../helpers/gaugeHelpers";

import debugApiData from "../json/gauges-debug.json";
//import apiData from "../json/2017-07-20.json";
	
class GaugeMapContainer extends Component {
	constructor({props}) {
		super(props);

		this.apiData = null;
		this.apiTimeout = null;

		this.getDataFromWebservice = this.getDataFromWebservice.bind(this);
		this.handleSlideoutClick = this.handleSlideoutClick.bind(this);
		this.prepInputDataForMap = this.prepInputDataForMap.bind(this);
		this.filterApiData = this.filterApiData.bind(this);

		this.outputTabularDamGaugeData = this.outputTabularDamGaugeData.bind(this)
		this.outputTabularWaterLevelGaugeData = this.outputTabularWaterLevelGaugeData.bind(this);
		this.outputTabularStreamFlowGaugeData = this.outputTabularStreamFlowGaugeData.bind(this);
		this.outputNonResponsiveGauges = this.outputNonResponsiveGauges.bind(this);
		this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
		this.getDamSafetyCodeData = this.getDamSafetyCodeData.bind(this);
		this.checkForSafetyCode = this.checkForSafetyCode.bind(this);
		this.formatRadarMapUpdateTime = this.formatRadarMapUpdateTime.bind(this);
		this.setSelectedPrecipBarTimeframe = this.setSelectedPrecipBarTimeframe.bind(this);
		this.getPrecipTotals = this.getPrecipTotals.bind(this);

		this.state = {
			damOpsStationData: null,
			radarMapLastUpdated: null,
			precipBarSelectedTime: null,
			precipitationTotals: null,
			fetchingDamOps: false,
			fetchingGaugeData: false
		};
	}

	UNSAFE_componentWillMount(){
		//just before this component initially mounts, set the state to the true
		window.scrollTo(0, 0);
		this.props.toggleSlideoutPanel(true);
	}

	componentDidMount(){ 
		// trigger fetching data 
		this.apiTimeout = setInterval( this.getDataFromWebservice, 60000 );
		this.cacheClear = setInterval( function(){ window.location.reload(true) }, 43200000 );
		this.getDataFromWebservice(); 
		this.getDamSafetyCodeData();
		//this.getPrecipTotals();
	}

	componentWillUnmount(){

		//clear the timeout for fetching data
		clearTimeout( this.apiTimeout );
		clearInterval( this.apiTimeout );
		clearInterval(this.cacheClear);
	}

	componentDidUpdate(prevProps, prevState){

        let wasPreviousLoading = false;
        let currentlyLoading = false;

        if( prevState.fetchingDamOps === true ||
            prevState.fetchingGaugeData ){
            wasPreviousLoading = true;
        }

        if( this.state.fetchingDamOps === true ||
            this.state.fetchingGaugeData === true ){
            currentlyLoading = true;
        }

        if( currentlyLoading === false && wasPreviousLoading === true ){
            //transitioning from loading
            this.props.togglePreloader(false);
        }
        else if ( currentlyLoading === true && wasPreviousLoading === false ){
            //transitioning to loading
            this.props.togglePreloader(true);
        }

        if( currentlyLoading && this.props.handleTogglePreloader === false ){
        	this.props.togglePreloader(true);
        }
        
    }
	
	getDataFromWebservice() {

		const useAPI = true;
		this.setState({fetchingGaugeData: true});
		

		if(useAPI){
			//fetch the data from the api
			let route = getRoute('gaugeListing');
			if( this.props.menuCheckboxStatus.thirdPartyData == 'on' ){
				route = getRoute('gaugeListing', [], {'include_third_party_gauges': true});
			}

			apiRequest( route )
			.then(result => {
				this.apiData = this.filterApiData(result);
				//console.log(this.apiData);
				this.props.updateGaugeMapApiData( this.apiData );
				this.setState({fetchingGaugeData: false});

			})
			.catch( (error) => {
				//let errorMsg = "";
				this.setState({fetchingGaugeData: false});
				/*switch(error) {d
					default:
					errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
					break;
				}
				this.props.togglePreloader(false);
				var obj = {
					isVisible: true,
					message: errorMsg,
				};

				this.props.showFetchError( obj );*/
			})
		}
		else{
			//uses the rain data from an event
			this.apiData = this.filterApiData(debugApiData);
			this.props.updateGaugeMapApiData( this.apiData );
			this.setState({fetchingGaugeData: false});
		}

		this.getPrecipTotals();
	}

	getDamSafetyCodeData() {  
		this.setState({fetchingDamOps: true});
		apiRequest( getRoute('damOpsStations', [], { is_dam: true })) 
		.then(result => {
            this.setState({
            	damOpsStationData: result,
            	fetchingDamOps: false
            }); 

        })
        .catch( (error) => {
			console.log(error.status);
			this.setState({fetchingDamOps: false});
        })
	}

	checkForSafetyCode(id) {
		//check if station has a dam safety code
		let safetyCode = null;
		if( this.state.damOpsStationData ) {
			for( var i = 0; i < this.state.damOpsStationData.results.length; i++ ) {
				if( id === this.state.damOpsStationData.results[i].station_id ) {

					safetyCode = this.state.damOpsStationData.results[i].dam_safety_code;
					
				}
			}
		}
		//console.log( safetyCode );
		return safetyCode;
	}

	//filters the apidata based on the selected inputs
	filterApiData(apiData){

		let results = [];

		for (var index = 0; index < apiData.length; index++) {

			const cd = apiData[index];
			if( cd.type_of === 'Dam Gauge' && this.props.updatedCheckboxData.damGauges === 'on'  ){
				results.push(cd);
			}
			else if( cd.type_of === 'Stream Gauge' && this.props.updatedCheckboxData.streamGauges === 'on'   ){
				results.push(cd);
			}
			else if( cd.type_of === 'Rain Gauge' && this.props.updatedCheckboxData.rainGauges === 'on'  ){
				results.push(cd);
			}
		}

		return results;
	}

	handleSlideoutClick() {
		//console.log("sup");
		this.props.handleToggleSlideoutPanel ? 
		this.props.toggleSlideoutPanel(false) : this.props.toggleSlideoutPanel(true)
	}

	prepInputDataForMap(checkboxId, checkboxStatus) {
		
		var checkObj = {
			id: checkboxId,
			status: checkboxStatus
		};

		this.props.handleCheckboxUpdate( checkObj );
		this.getDataFromWebservice();
	}

	outputNonResponsiveGauges(){

		if( !this.apiData){
			return null;
		}

		let nonResponsiveGauges = [];

		for (var index = 0; index < this.apiData.length; index++) {

			if( this.apiData[index].status === "Non-responsive" ){
				nonResponsiveGauges.push({
					'stationName' : this.apiData[index].station_name,
					'stationSlug' : this.apiData[index].slug,
					'sensorName'	: this.apiData[index].sensor_name,
					'lastUpdated' : this.apiData[index].last_updated,
					'missedReadings' : this.apiData[index].number_of_missed_readings 
				});
			}

		}

		if( nonResponsiveGauges.length){

			return (
				<div className="non-responsive-gauges">

					<h2>Non Responsive Gauges</h2>
					<div className="responsive-table-wrap">
						<table className="table-lined">
							<thead>
								<tr>
									<th>Station</th>
									<th>Gauge</th>
									<th className="col-center">Last Measurement</th>
									<th className="col-center">Measurements Missed</th>
								</tr>
							</thead>
							<tbody>
								{
									nonResponsiveGauges.map( (gauge, index) => {

										var lastUpdate = new Date( gauge.lastUpdated );
										lastUpdate = yyyymmdd( lastUpdate ) + ' ' + hhmmampm( lastUpdate)
										//console.log(gauge);
										return (
											<tr key={index}>
												<td><Link to={ "/gauge/"+gauge.stationSlug  }>{ gauge.stationName }</Link></td>
												<td>{gauge.sensorName}</td>
												<td className="col-center">{lastUpdate}</td>
												<td className="col-center">{gauge.missedReadings}</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		else{
			return (
				<div className="non-responsive-gauges">
					<h2>Non Responsive Gauges</h2>
					<p>All gauges are reporting.</p>
				</div>
			)
		}

	}


	outputTabularWaterLevelGaugeData(type_of, section_title){

		if( !this.apiData){
			return null;
		}

		let tabularStreamGauges = [];	
		
		//https://trello.com/c/y3VbeFFX/276-main-page-tabular-data

		for (var index = 0; index < this.apiData.length; index++) {

			if( this.apiData[index].type_of !== 'Dam Gauge' ) { //filter out Dam gauges
				if( this.apiData[index].sensor_name === 'WL' ){
					let streamGauge = {
						'stationName': this.apiData[index].station_name,
						'sensorName': this.apiData[index].sensor_name,   
						'stationSlug': this.apiData[index].slug,
						'trendArrow':  getTrendIcon(this.apiData[index]),
						'lastUpdated': '',
						'lastUpdatedClass': '',
						'currentWaterLevel': '',
						'currentWaterLevelRaw': null,
						'normalWaterLevel': 'N/A',
						'lowAlarm': 'N/A',
						'highAlarm': 'N/A',
						'highHighAlarm': 'N/A',
						'inAlarmState': false
					}
	
	
					// set the last update date
					var lastUpdate = new Date( this.apiData[index].last_updated );
					streamGauge.lastUpdated = yyyymmdd( lastUpdate ) + ' ' + hhmmampm( lastUpdate );
	
					//get the current level
					streamGauge.currentWaterLevel = 'N/A';
					for (var gaugeIndex = 0; gaugeIndex < this.apiData[index].time_series_data.length; gaugeIndex++) {
						if( this.apiData[index].time_series_data[gaugeIndex].measured_value !== null ){
							streamGauge.currentWaterLevel = formatGaugeAmount( this.apiData[index].time_series_data[gaugeIndex].measured_value, false, "WL");
							streamGauge.currentWaterLevelRaw = this.apiData[index].time_series_data[gaugeIndex].measured_value;
							if( gaugeIndex !== 0){
								streamGauge.lastUpdatedClass = 'missed-measurement';
							}
							break;
						}
					}
	
					if(streamGauge.currentWaterLevel === 'N/A' ){
						streamGauge.lastUpdatedClass = 'missed-measurement'; 
					}
	
					// get the normal for this monht
					if( this.apiData[index].normal_for_this_month ){
						streamGauge.normalWaterLevel = formatGaugeAmount(this.apiData[index].normal_for_this_month, false, "WL");
					}
	
					
					//console.log( this.apiData[index] );
	
					// set the low alarm
					if( this.apiData[index].low_alarm === true){
						streamGauge.lowAlarm = formatGaugeAmount(this.apiData[index].low_limit, false, "WL");
						if( streamGauge.currentWaterLevelRaw && streamGauge.currentWaterLevelRaw >= this.apiData[index].low_limit ){
							streamGauge.inAlarmState = true;
						}
					}
	
					// set the high alarm
					if( this.apiData[index].high_alarm === true){
						streamGauge.highAlarm = formatGaugeAmount(this.apiData[index].high_limit, false, "WL");
						if( streamGauge.currentWaterLevelRaw && streamGauge.currentWaterLevelRaw >= this.apiData[index].high_limit ){
							streamGauge.inAlarmState = true;
						}
					}
	
					//if the high high is set add it to the graph
					if( this.apiData[index].high_high_alarm === true ){
						streamGauge.highHighAlarm = formatGaugeAmount(this.apiData[index].high_high_limit, false, "WL");
						if( streamGauge.currentWaterLevelRaw && streamGauge.currentWaterLevelRaw >= this.apiData[index].high_high_limit ){
							streamGauge.inAlarmState = true;
						}
					}
	
	
					tabularStreamGauges.push(streamGauge);
	
				}
			}
		}


		if( tabularStreamGauges.length ){

			return(
				<div className="tabular-stream-gauges">
					<h2>Stream Gauges</h2>
					<div className="responsive-table-wrap">
						<table className="table-lined">
							<thead>
								<tr>
									<th>Station Name</th>
									<th>Trend</th>
									<th>Last Updated</th>
									<th className="col-center">Current Water Level (m)</th>
									<th className="col-center">Normal Water Level (m)</th>
									<th className="col-center">Low Alarm</th>
									<th className="col-center">High Alarm</th>
									<th className="col-center">High High Alarm</th>
									<th className="col-center">Status of Alarms</th>
								</tr>
							</thead>
							<tbody>
								{
									tabularStreamGauges.map( (streamGauge, index) => {
										//console.log( streamGauge );
										return (
											<tr key={index}>
												<td><Link to={ "/gauge/"+streamGauge.stationSlug  } aria-label={ streamGauge.stationName + ": " + streamGauge.sensorName }>{ streamGauge.stationName }</Link></td>
												<td className="tabular-icon"><SvgIcon icon={streamGauge.trendArrow} /></td>
												<td className={streamGauge.lastUpdatedClass}>{ streamGauge.lastUpdated }</td>
												<td className="col-center" dangerouslySetInnerHTML={{ __html: streamGauge.currentWaterLevel }}></td>
												<td className="col-center" dangerouslySetInnerHTML={{ __html: streamGauge.normalWaterLevel }}></td>
												<td className="col-center">{ streamGauge.lowAlarm }</td>
												<td className="col-center">{ streamGauge.highAlarm }</td>
												<td className="col-center">{ streamGauge.highHighAlarm }</td>
												<td className="col-center alarm-state tabular-icon"><SvgIcon icon={(streamGauge.inAlarmState ? 'cross' : 'check' )} /></td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>

			)

		}
		else{
			return null;
		}

	}



	outputTabularStreamFlowGaugeData(){

		if( !this.apiData){
			return null;
		}

		let tabularStreamGauges = [];		

		for (var index = 0; index < this.apiData.length; index++) {

			if( this.apiData[index].sensor_name === 'DISCHARGE' ){
				let streamGauge = {
					'stationName': this.apiData[index].station_name,
					'sensorName': this.apiData[index].sensor_name,
					'stationSlug': this.apiData[index].slug,
					'trendArrow':  getTrendIcon(this.apiData[index]),
					'lastUpdated': '',
					'lastUpdatedClass': '',
					'currentWaterLevel': '',
					'currentWaterLevelRaw': null,
				}


				// set the last update date
				var lastUpdate = new Date( this.apiData[index].last_updated );
				streamGauge.lastUpdated = yyyymmdd( lastUpdate ) + ' ' + hhmmampm( lastUpdate );
				


				//get the current level
				streamGauge.currentWaterLevel = 'N/A';
				for (var gaugeIndex = 0; gaugeIndex < this.apiData[index].time_series_data.length; gaugeIndex++) {
					if( this.apiData[index].time_series_data[gaugeIndex].measured_value !== null ){
						streamGauge.currentWaterLevel = formatGaugeAmount( this.apiData[index].time_series_data[gaugeIndex].measured_value, formatMeasurement(this.apiData[index].unit_of_measure) );
						streamGauge.currentWaterLevelRaw = this.apiData[index].time_series_data[gaugeIndex].measured_value;
						if( gaugeIndex !== 0){
							streamGauge.lastUpdatedClass = 'missed-measurement';
						}
						break;
					}
				}

				if(streamGauge.currentWaterLevel === 'N/A' ){
					streamGauge.lastUpdatedClass = 'missed-measurement';
				}


				tabularStreamGauges.push(streamGauge);

			}

		}


		if( tabularStreamGauges.length ){

			return(
				<div className="tabular-stream-gauges">
					<h2>Streamflow</h2>
					<div className="responsive-table-wrap">
						<table className="table-lined">
							<thead>
								<tr>
									<th>Station Name</th>
									<th>Trend</th>
									<th className="col-center">Last Updated</th>
									<th className="col-center">Current Discharge ( m<sup>3</sup>/s )</th>
								</tr>
							</thead>
							<tbody>
								{
									tabularStreamGauges.map( (streamGauge, index) => {
										return (
											<tr key={index}>
												<td><Link to={ "/gauge/"+streamGauge.stationSlug  } aria-label={ streamGauge.stationName + ": " + streamGauge.sensorName }>{ streamGauge.stationName }</Link></td>
												<td className="tabular-icon"><SvgIcon icon={streamGauge.trendArrow} /></td>
												<td className={`col-center ${streamGauge.lastUpdatedClass}`}>{ streamGauge.lastUpdated }</td>
												<td className="col-center" dangerouslySetInnerHTML={{ __html: streamGauge.currentWaterLevel }}></td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>

			)

		}
		else{
			return null;
		}

	}



	outputTabularDamGaugeData( section_title ){

		if( !this.apiData){
			return null;
		}

		let type_of = 'Dam Gauge';

		let tabularStreamGauges = [];

		for (var index = 0; index < this.apiData.length; index++) {

			if( this.apiData[index].type_of === type_of ){
				let streamGauge = {
					'stationName': this.apiData[index].station_name,
					'sensorName': 'STORAGE',
					'stationSlug': this.apiData[index].slug,
					'trendArrow':  getTrendIcon(this.apiData[index]),
					'lastUpdated': '',
					'lastUpdatedClass' : '',
					'currentWaterLevel': '',
					'currentWaterLevelRaw': null,
					'normalWaterLevel': 'N/A',
					'lowAlarm': 'N/A',
					'highAlarm': 'N/A',
					'highHighAlarm': 'N/A',
					'inAlarmState': false
				}


				// set the last update date
				var lastUpdate = new Date( this.apiData[index].last_updated );
				streamGauge.lastUpdated = yyyymmdd( lastUpdate ) + ' ' + hhmmampm( lastUpdate );
				


				//get the current level
				streamGauge.currentWaterLevel = 'N/A';
				for (var gaugeIndex = 0; gaugeIndex < this.apiData[index].time_series_data.length; gaugeIndex++) {
					if( this.apiData[index].time_series_data[gaugeIndex].measured_value !== null ){
						streamGauge.currentWaterLevel = formatGaugeAmount( this.apiData[index].time_series_data[gaugeIndex].measured_value, false, "WL" );
						streamGauge.currentWaterLevelRaw = this.apiData[index].time_series_data[gaugeIndex].measured_value;
						if( gaugeIndex !== 0){
							streamGauge.lastUpdatedClass = 'missed-measurement';
						}
						break;
					}
				}
				if(streamGauge.currentWaterLevel === 'N/A' ){
					streamGauge.lastUpdatedClass = 'missed-measurement';
				}

				// get the normal for this monht
				if( this.apiData[index].normal_for_this_month ){
					streamGauge.normalWaterLevel = formatGaugeAmount(this.apiData[index].normal_for_this_month, false, "WL");
				}



				// set the low alarm
				if( this.apiData[index].low_alarm === true){
					streamGauge.lowAlarm = formatGaugeAmount(this.apiData[index].low_limit, false, "WL");
					if( streamGauge.currentWaterLevelRaw && streamGauge.currentWaterLevelRaw >= this.apiData[index].low_limit ){
						streamGauge.inAlarmState = true;
					}
				}

				// set the high alarm
				if( this.apiData[index].high_alarm === true){
					streamGauge.highAlarm = formatGaugeAmount(this.apiData[index].high_limit, false, "WL");
					if( streamGauge.currentWaterLevelRaw && streamGauge.currentWaterLevelRaw >= this.apiData[index].high_limit ){
						streamGauge.inAlarmState = true;
					}
				}

				//if the high high is set add it to the graph
				if( this.apiData[index].high_high_alarm === true ){
					streamGauge.highHighAlarm = formatGaugeAmount(this.apiData[index].high_high_limit, false, "WL");
					if( streamGauge.currentWaterLevelRaw && streamGauge.currentWaterLevelRaw >= this.apiData[index].high_high_limit ){
						streamGauge.inAlarmState = true;
					}
				}


				tabularStreamGauges.push(streamGauge);

			}

		}


		if( tabularStreamGauges.length ){

			return(
				<div className="tabular-stream-gauges">
					<h2>Dam Gauges</h2>
					<div className="responsive-table-wrap">
						<table className="table-lined">
							<thead>
								<tr>
									<th>Station Name</th>
									<th>Trend</th>
									<th>Last Updated</th>
									<th className="col-center">Current Water Level (m)</th>
									<th className="col-center">Normal Water Level (m)</th>
									<th className="col-center">Low Alarm</th>
									<th className="col-center">High Alarm</th>
									<th className="col-center">High High Alarm</th>
									<th className="col-center">Status of Alarms</th>
								</tr>
							</thead>
							<tbody>
								{
									tabularStreamGauges.map( (streamGauge, index) => {
										return (
											<tr key={index}>
												<td><Link to={ "/gauge/"+streamGauge.stationSlug  } aria-label={ streamGauge.stationName + ": " + streamGauge.sensorName }>{ streamGauge.stationName }</Link></td>
												<td className="tabular-icon"><SvgIcon icon={streamGauge.trendArrow} /></td>
												<td className={streamGauge.lastUpdatedClass}>{ streamGauge.lastUpdated }</td>
												<td className="col-center" dangerouslySetInnerHTML={{ __html: streamGauge.currentWaterLevel }}></td>
												<td className="col-center" dangerouslySetInnerHTML={{ __html: streamGauge.normalWaterLevel }}></td>
												<td className="col-center">{ streamGauge.lowAlarm }</td>
												<td className="col-center">{ streamGauge.highAlarm }</td>
												<td className="col-center">{ streamGauge.highHighAlarm }</td>
												<td className="col-center alarm-state tabular-icon"><SvgIcon icon={(streamGauge.inAlarmState ? 'cross' : 'check' )} /></td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>

			)

		}
		else{
			return null;
		}

	}

	outputTabularRainData(){


		if( !this.apiData){
			return null;
		}

		let tabularRainData = [];

		for (var index = 0; index < this.apiData.length; index++) {

			if( this.apiData[index].type_of === 'Rain Gauge' ){

				let lastUpdatedClass = 'missed-measurement';
				if( this.apiData[index].time_series_data[0] && this.apiData[index].time_series_data[0].measured_value !== null  ){
					lastUpdatedClass = '';
				}
				//console.log(`${this.apiData[index].station_name} - ${lastUpdatedClass}`);

				tabularRainData.push({
					stationName: <Link to={ "/gauge/"+this.apiData[index].slug  } aria-label={ this.apiData[index].station_name + ": PRECIP" }>{ this.apiData[index].station_name }</Link>,
					lastMeasurement: this.apiData[index].last_updated,
					lastUpdatedClass: lastUpdatedClass,
					accumulatedTotals: this.apiData[index].accumulated_totals
				});
			}

		}


		if( tabularRainData.length ){

			return (
				<div className="tabular-stream-gauges">
					<h2>Precipitation Gauges (mm)</h2>
					<RainAccumulatedTotals rainGaugeTotalCollection={tabularRainData} />
				</div>
			)
		}
		else{
			return null;
		}

	}

	dismissErrorMessage() { 
		var obj = {
			isVisible: false,
			message: "",
		};

		this.props.showFetchError( obj );
	}

	formatRadarMapUpdateTime(date) {
		//console.log(date);
		this.setState({ radarMapLastUpdated: date });
	}

	setSelectedPrecipBarTimeframe( value ) {
		//console.log("value : "+value);
		this.setState({ precipBarSelectedTime: value });
	}

	getPrecipTotals() {
		apiRequest( getRoute("precipTotals"))
		.then(result => {
			this.setState({ precipitationTotals: result.results });
		})
		.catch( (error) => {
			console.log( 'Error', error );
		})
    } 

	render() {

		if( this.apiData ) {
			this.cardData = [];

			for (var index = 0; index < this.apiData.length; index++) {

				const cd = this.apiData[index];

				if( cd.type_of === 'Dam Gauge' ){
					this.cardData.push(
						<Card 
							key={ cd.station_id + "-" + cd.station_name + index + '-dam-' + index } 
							id={ slugify(cd.station_id + "-" + cd.station_name + '-dam-' + index) }
							cardType="dam-gauge"
							data={ cd }
							isLoggedIn={this.props.isLoggedIn} 
							precipTotals={ null }
						/>);

					this.cardData.push(
						<Card 
							key={ cd.station_id + "-" + cd.station_name + index + '-stream-' + index } 
							id={ slugify(cd.station_id + "-" + cd.station_name  + '-stream-' + index )}
							cardType="time-series"
							data={ cd } 
							safetyCode={this.checkForSafetyCode(cd.station_id)}
							isLoggedIn={this.props.isLoggedIn} 
							precipTotals={ null }
						/>);

				}
				else if( cd.type_of === 'Stream Gauge'  ){
					this.cardData.push (
						<Card 
							key={ cd.station_id + "-" + cd.station_name + index + '-stream-' + index  } 
							id={ slugify(cd.station_id + "-" + cd.station_name) + '-stream-' + index }
							cardType="time-series"
							data={ cd } 
							safetyCode={this.checkForSafetyCode(cd.station_id)}
							isLoggedIn={this.props.isLoggedIn} 
							precipTotals={ null }
						/>
					);
				}
				else if( cd.type_of === 'Rain Gauge' ){
					let precipTotals = null;

					if( this.state.precipitationTotals ) {
						//console.log("*************************************");
						//console.log( this.state.precipitationTotals );
						//console.log( cd );

						for( var i=0; i < this.state.precipitationTotals.length; i++ ) {
							if( this.state.precipitationTotals[i].slug === cd.slug ) {
								precipTotals = this.state.precipitationTotals[i];
							}
						}
					}

					//console.log(precipTotals);

					this.cardData.push (
						<Card 
							key={ cd.station_id + "-" + cd.station_name + index + '-rain-' + index  } 
							id={ slugify(cd.station_id + "-" + cd.station_name) + '-rain-' + index }
							cardType="rain-gauge"
							data={ cd } 
							isLoggedIn={this.props.isLoggedIn} 
							precipTotals={ precipTotals }
						/>
					);
				}
				
			}

			var googleMap = 
			<GoogleMap 
				apiData = { this.apiData } 
				checkboxUpdated = { this.props.updatedCheckboxData}
				panelOut={this.props.handleToggleSlideoutPanel}
				watershedBoundariesIsOn={this.props.updatedCheckboxData.watershedBoundaries}
				weatherRadarIsOn={this.props.updatedCheckboxData.weatherRadar}
				floodPlainIsOn= {this.props.updatedCheckboxData.floodPlain}
				floodLineIsOn= {this.props.updatedCheckboxData.floodLine}
				precipitationBarsIsOn={this.props.updatedCheckboxData.precipitationBars}
				precipitationBarsSelectedTimeframe={ this.state.precipBarSelectedTime }
				radarMapUpdateTime={ this.formatRadarMapUpdateTime }
				mapId={'map'}
			/>
		}

		let radarMapLegend = (this.props.updatedCheckboxData.weatherRadar === "on") ? 
		<RadarMapLegend  
			radarCheckboxStatus={ this.props.updatedCheckboxData.weatherRadar }
			radarMapLastUpdated={ this.state.radarMapLastUpdated }
		/> : null;		

		let precipBarLegend = (this.props.updatedCheckboxData.precipitationBars === "on") ? 
		<PrecipBarLegend  
			selectedPrecipBarTimeframe = { this.setSelectedPrecipBarTimeframe }
		/> : null;
		//console.log(this.props.location.pathname);
		let feedbackWidget = this.props.isLoggedIn ? <FeedbackWidget location={ this.props.location.pathname }/> : null;

		return (
			<div className="" role="main">
				<Helmet>
                    <title>{ pageTitle('Gauges') }</title>
                </Helmet>
				<h1 id="main-heading" className="screen-reader-text">Station Map and Gauge listing</h1>	
				<div className="map-container clear" role="region" aria-labelledby="gauge-map-region">
					<h2 className="screen-reader-text" id="gauge-map-region">Map with stations plotted</h2>
					<Preloader showPreloader={ this.props.handleTogglePreloader } />
					<ErrorMsg 
						showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
						message={ this.props.fetchErrorMsg.errorMessage } 
						dismissErrorMessage={ this.dismissErrorMessage }	
					/>
					
					<SlideoutPanel 
						onSlideoutClick={ this.handleSlideoutClick }
						doSlideoutPanel={ this.props.handleToggleSlideoutPanel }
						pageRef="gaugeMap"
						menuData={
							<MapMenu 
								inputChangedData={ this.prepInputDataForMap }
								menuStatus={ this.props.menuCheckboxStatus }
							/>
						} 
					/>
					{ radarMapLegend }
					{ precipBarLegend }
					<div className="map-panel">
						{ googleMap }
					</div>
				</div> 
				<div className="map-wrap"  role="region" aria-labelledby="gauge-card-region">
					<h2 className="screen-reader-text" id="gauge-card-region">Cards with station details</h2>
					<div className="map-content"> 
						<div className="card-wrap">
							{ this.cardData }
						</div>
						

					</div>    
				</div>

				<div className="map-wrap"  role="region" aria-labelledby="tabular-data-region">
					<h2 className="screen-reader-text" id="tabular-data-region">Tabular data for gauges</h2>
					<div className="container-fluid map-tables">
						<div className="row">
							<div className="col-sm-12">
								{ this.outputNonResponsiveGauges() }
								{ this.outputTabularWaterLevelGaugeData() }
								{ this.outputTabularStreamFlowGaugeData() }
								{ this.outputTabularDamGaugeData() }
								{ this.outputTabularRainData() }

							</div>
						</div>
					</div>
				</div>

				<div className="map-wrap"  role="region" aria-labelledby="feedback-region">
					<h2 className="screen-reader-text" id="feedback-region">Widget for providing feedback</h2>
					<div className="container-fluid map-tables">	
						<div className="row">
							<div className="col-sm-12">
								{ feedbackWidget }
							</div>
						</div>
					</div>
				</div>	
			</div> 
		)
	}
}

function mapStateToProps(state) {
	//console.log("************");
	//console.log(state);
	//console.log("************");
	
	return { 
		handleTogglePreloader: state.togglePreloader,
		handleToggleSlideoutPanel: state.toggleSlideoutPanel,
		updatedCheckboxData: state.handleCheckboxUpdate,
		handleGaugeMapApiData: state.gaugeMapApiData,
		menuCheckboxStatus: state.handleCheckboxUpdate,
		fetchErrorMsg: state.fetchErrorMsg,
		isLoggedIn: state.auth.isLoggedIn
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
		togglePreloader: togglePreloader,
		toggleSlideoutPanel: toggleSlideoutPanel,
		handleCheckboxUpdate: handleCheckboxUpdate,
		updateGaugeMapApiData: updateGaugeMapApiData,
		showFetchError: showFetchError,
	}, dispatch)
}

export default connect( mapStateToProps, matchDispatchToProps )( GaugeMapContainer ); 